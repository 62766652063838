<template>
  <div class="module" style="padding-top: 0!important;">
    <v-simple-table id="profitAndLoss" class="table table-first-column-highlight" style="border-spacing:0;">
      <template v-slot:default>
        <thead>
        <tr>
          <th rowspan="1" colspan="1"></th>
          <th
              v-for="date in dateColumns"
              :key="date">
            {{ date }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td :colspan="dateColumns.length+1">
            <div class="profitAndLoss-upgrade-holder">
              <div class="profitAndLoss-text">
                {{ $t(text) }}
              </div>
              <router-link
                  class="btn-upgrade profitAndLoss-button"
                  :to="{name: 'Subscription'}"
              >
                {{ $t(btn) }}</router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ProfitAndLossNotAvailable",
  data() {
    return {
        text: this.$store.state.selectedProject.shop_type == 'shoprenter' ? 'profitAndLoss.notAvailableSR' : 'profitAndLoss.notAvailable',
        btn: this.$store.state.selectedProject.shop_type == 'shoprenter' ? 'common.upgradeSR' : 'common.upgrade'
    }
  },
  computed: {
    start() {
      return Number(this.$dayjs(this.$store.state.selectedPeriod.startDate).subtract(8, 'month').startOf('month').add(this.$dayjs().utcOffset(), 'minute').format('X'))
    },
    end() {
      return Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
    },
    dateColumns() {
      let dates = [];
      for(let i = this.start; i < this.end; i = this.$dayjs.unix(i).add(1, 'month').format('X')){
        const date = this.$dayjs.unix(i).format('ll');
        dates.push(date);
      }

      return dates.reverse()
    },
  }
}
</script>

<style scoped>

</style>