import dayjs from "dayjs";
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
    weekStart: 1,
})

export function calculatePeriod(type) {
    const date = dayjs().subtract(1, 'day').toDate()
    switch (type) {
        case 'yesterday':
            return {
                startDate: dayjs(date).startOf('day').toDate(),
                endDate: dayjs(date).endOf('day').toDate(),
            }
        case 'aWeekAgo':
            return {
                startDate: dayjs(date).subtract(1, 'week').startOf('day').toDate(),
                endDate: dayjs(date).subtract(1, 'week').endOf('day').toDate(),
            }
        case 'thisWeek':
            return {
                startDate: dayjs(date).startOf('week').toDate(),
                endDate: dayjs(date).endOf('day').toDate(),
            }
        case 'lastWeek':
            return {
                startDate: dayjs(date).subtract(1, 'week').startOf('week').toDate(),
                endDate: dayjs(date).endOf('day').subtract(1, 'week').toDate(),
            }
        case 'thisMonth':
            return {
                startDate: dayjs(date).startOf('month').toDate(),
                endDate: dayjs(date).endOf('day').toDate(),
            }
        case 'lastMonth':
            return {
                startDate: dayjs(date).subtract(1, 'month').startOf('month').toDate(),
                endDate: dayjs(date).endOf('day').subtract(1, 'month').toDate(),
            }
        case 'thisYear':
            return {
                startDate: dayjs(date).startOf('year').toDate(),
                endDate: dayjs(date).endOf('day').toDate(),
            }
        case 'lastYear':
            return {
                startDate: dayjs(date).subtract(1, 'year').startOf('year').toDate(),
                endDate: dayjs(date).endOf('day').subtract(1, 'year').toDate(),
            }
    }

}