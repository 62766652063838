<template>
  <div class="module">
    <kpi-with-chart :data="kpiData" :mainKpiData="mainKpiData" />
  </div>
</template>

<script>
import KpiWithChart from "@/components/common/KpiWithChart";
import AD_SPEND_BREAKDOWN from "@/graphql/dashboard/adSpendBreakdown.gql";
export default {
  name: "AdSpendBreakdown",
  components: {KpiWithChart},
  data() {
    return {
      kpiData: {
        selectedPeriod: {},
        previousPeriod:  {}
      },
      mainKpiData: {
        selected: {},
        previous:  {},
        yesterday: {},
        aWeekAgo: {},
        thisWeek: {},
        lastWeek: {},
        thisMonth: {},
        lastMonth: {},
        thisYear: {},
        lastYear: {},
      }
    }
  },
  async created() {
    await this.reCalculateData()
    if (this.$store.state.selectedProject.projectRole !== 'FREE') {
      await this.recalculateMainKpiData()
    }
  },
  watch: {
    '$store.state.selectedPeriod'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod:  {}
      }
      this.mainKpiData.selected = {}
      this.reCalculateData()
    },
    '$store.state.selectedProject'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod:  {}
      }
      this.mainKpiData= {
        selected: {},
        previous:  {},
        yesterday: {},
        aWeekAgo: {},
        thisWeek: {},
        lastWeek: {},
        thisMonth: {},
        lastMonth: {},
        thisYear: {},
        lastYear: {},
      }
      this.reCalculateData()
      if (this.$store.state.selectedProject.projectRole !== 'FREE') {
        this.recalculateMainKpiData()
      }
    }
  },
  methods: {
    async reCalculateData() {
      this.kpiData.selectedPeriod = await this.getKpiData(
          this.$store.state.selectedPeriod.startDate,
          this.$store.state.selectedPeriod.endDate
      )
      this.kpiData.previousPeriod = await this.getKpiData(
          this.$store.state.previousPeriod.startDate,
          this.$store.state.previousPeriod.endDate
      )
      this.mainKpiData.selected = this.kpiData.selectedPeriod
      this.mainKpiData.previous = this.kpiData.previousPeriod
    },
    async recalculateMainKpiData() {
      for (const [periodKey, period] of Object.entries(this.$store.state.mainKpiPeriods)) {
        this.mainKpiData[periodKey] = await this.getKpiData(
            period.startDate,
            period.endDate
        )
      }
    },
    getKpiData(start, end) {
      const variables = {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs(start).add(this.$dayjs(start).utcOffset(), 'minute').format('X')),
        dateEnd: Number(this.$dayjs(end).add(this.$dayjs(end).utcOffset(), 'minute').format('X'))
      }
      return this.$apollo.query({
        query: AD_SPEND_BREAKDOWN,
        variables,
        fetchPolicy: 'network-only',
        loadingKey: 'loading',
        error(error) {
          console.error("We've got an error!", error)
        }
      }).then((response) => {
        const roas =
            response.data.dashboardKPI.totalAdSpend ?
                response.data.dashboardKPI.ordersFromAds.netSales/response.data.dashboardKPI.totalAdSpend :
                0
        const adSpendPerOrder =
            response.data.dashboardKPI.ordersFromAds.numberOfOrders ?
                response.data.dashboardKPI.totalAdSpend/response.data.dashboardKPI.ordersFromAds.numberOfOrders :
                0
        return {
          netSalesFromAds: response.data.dashboardKPI.ordersFromAds.netSales,
          adSpend: response.data.dashboardKPI.totalAdSpend,
          roas: roas,
          ordersFromAds: response.data.dashboardKPI.ordersFromAds.numberOfOrders,
          adSpendPerOrder: adSpendPerOrder,
          sessionsFromAds: response.data.dashboardKPI.sessionsFromAds,
          conversionRateOfAds: response.data.dashboardKPI.sessionsFromAds > 0 ?
              response.data.dashboardKPI.ordersFromAds.numberOfOrders / response.data.dashboardKPI.sessionsFromAds : 0,
        }
      })
    }
  }
}
</script>

<style scoped>

</style>