<template>
  <div>
    <v-row>
      <v-col
          sm="10"
          md="9"
          class="pl-12 pb-30 d-flex flex-row"
      >
        <v-checkbox
            v-model="visiblePeriods"
            class="mr-3"
            :label="$t('chart.periods.selected')"
            color="#623C9F"
            value="selected"
            hide-details
        ></v-checkbox>
        <v-checkbox
            v-model="visiblePeriods"
            class="mr-3"
            :label="$t('chart.periods.previous')"
            color="#D8C0FF"
            value="previous"
            hide-details
        ></v-checkbox>
        <v-checkbox
            v-model="visiblePeriods"
            class="mr-3"
            :label="$t('chart.periods.aYearAgo')"
            color="#999999"
            value="aYearAgo"
            hide-details
        ></v-checkbox>
      </v-col>
      <v-col sm="12" md="3" class="performance-unit-select">
        <v-select
            v-model="unit"
            :items="unitItems"
            dense
            outlined
            hide-details
            v-if="visibleUnitSelect"
        ></v-select>
      </v-col>
    </v-row>
    <apexchart v-if="loaded" ref="performanceChart" :type="chartType" height="420" :options="chartOptions" :series="series"></apexchart>
    <div v-else class="no-data no-data-data-loading d-flex align-items-center justify-content-center text-center pt-10" style="height: 380px;">
      <div class="data-loading">
        <v-img
            class="my-3"
            contain
            :src="require('@/assets/icons/dashboard.svg')"
            width="100"
        />
        <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
import NET_SALES from '@/graphql/dashboard/kpiChart/netSales.gql'
import AD_SPEND_PER_NET_SALES from '@/graphql/dashboard/kpiChart/adSpendPerNetSales.gql'
import GROSS_PROFIT from '@/graphql/dashboard/kpiChart/grossProfit.gql'
import ORDERS from '@/graphql/dashboard/kpiChart/orders.gql'
import AOV from '@/graphql/dashboard/kpiChart/aov.gql'
import SESSIONS from '@/graphql/dashboard/kpiChart/sessions.gql'
import CONVERSION_RATE from '@/graphql/dashboard/kpiChart/conversionRate.gql'
import AD_SPEND from '@/graphql/dashboard/kpiChart/adSpend.gql'
import AD_SPEND_PER_ORDER from '@/graphql/dashboard/kpiChart/adSpendPerOrder.gql'
import CONVERSION_RATE_OF_ADS from '@/graphql/dashboard/kpiChart/conversionRateOfAds.gql'
import NET_SALES_FROM_ADS from '@/graphql/dashboard/kpiChart/netSalesFromAds.gql'
import ORDERS_FROM_ADS from '@/graphql/dashboard/kpiChart/ordersFromAds.gql'
import ROAS from '@/graphql/dashboard/kpiChart/roas.gql'
import SESSIONS_FROM_ADS from '@/graphql/dashboard/kpiChart/sessionsFromAds.gql'
import ValueFormats from "@/config/ValueFormats.json";

export default {
  name: "PerformanceChart",
  props: {
    selectedKpi: {
      type: String
    },
    selectedMainKpi: {
      type: String
    }
  },
  data() {
    return {
      chartType: 'area',
      visibleUnitSelect: true,
      unit: 2,
      unitItems: [
        {
          value: 2,
          text: this.$t('chart.units.daily'),
        },
        {
          value: 4,
          text: this.$t('chart.units.monthly'),
        }
      ],
      visiblePeriods: ['selected', 'previous'],
      chartData: {
        aYearAgo: {},
        previous: {},
        selected: {}
      },
      loaded: false
    }
  },
  watch: {
    visiblePeriods() {
      this.updateSeriesByPeriod()
    },
    async '$store.state.selectedPeriod'() {
      this.chartData = await this.getChartData(true);
      setTimeout(() => {
        this.updateSeriesByPeriod()
      }, 1000)
    },
    async '$store.state.selectedProject'() {
      this.chartData = await this.getChartData(true);
      setTimeout(() => {
        this.updateSeriesByPeriod()
      }, 1000)
    },
    async selectedView() {
      if (!this.selectedKpi || !this.selectedMainKpi) {
        return
      }

      this.chartData = await this.getChartData(true);
    },
    async unit() {
      this.chartData = await this.getChartData(false);
    },
    selectedMainKpi() {
      this.visibleUnitSelect = this.selectedMainKpi !== 'yesterday';
    },
    series() {
      this.loaded = true
    },
  },
  computed: {
    selectedView() {
      return this.selectedKpi + '-' + this.selectedMainKpi
    },
    chartSeries() {
      return{
        aYearAgo: {
          name: "aYearAgo",
          data: Object.values(this.chartData.aYearAgo)
        },
        previous: {
          name: "previous",
          data: Object.values(this.chartData.previous)
        },
        selected: {
          name: "selected",
          data: Object.values(this.chartData.selected)
        }
      }
    },
    series() {
      return Object.values(this.chartSeries)
    },
    labels() {
      return Object.keys(this.chartData.selected)
    },
    chartOptions() {
      return {
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 100,
          },
          type: this.chartType,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {
            mounted: () => {
              this.updateSeriesByPeriod()
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '10%',
            colors: {
              backgroundBarOpacity: 1,
            },
          }
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          strokeWidth: 3
        },
        colors: ['#e4e6ed', '#d8c0ff', '#975FF2'],
        labels: Object.keys(this.chartData.selected),
        yaxis: {
          type: 'category',
          labels: {
            trim: false,
            formatter: (value) => {
              let format = 'number'
              if (this.selectedKpi) {
                format = ValueFormats[this.selectedKpi].kpi
              }
              return this.$options.filters.format(value, format);
            }
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            trim: false,
            formatter: (timestamp) => {
              if (this.unit === 2) {
                if (this.labels.length > 14) {
                  if (Number(this.$dayjs.unix(timestamp).format("d")) === 1) {
                    return this.$dayjs.unix(timestamp).format('lll');
                  } else {
                    return "";
                  }
                }
                return this.$dayjs.unix(timestamp).format('lll');
              }
              if (this.unit === 1) {
                return this.$dayjs.unix(timestamp).format('HH:mm');
              }
              if (this.unit === 4) {
                if (this.$dayjs.unix(timestamp).format('M') === '1') {
                  return this.$dayjs.unix(timestamp).format('YYYY. MMM');
                }
                return this.$dayjs.unix(timestamp).format('MMM');
              }
            }
          },
          trim: false,
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        grid: {
          strokeDashArray: 3,
          yaxis: {
            lines: {
              show: true,
              offsetX: 0,
              offsetY: 0
            }
          }
        },
        fill: {
          type: 'solid',
          opacity: this.chartType === 'area' ? [0.2, 0.1, 0.05] : [0.8,0.8,0.8]
        },
        stroke: {
          width: [3, 3, 3],
          curve: 'straight',
          dashArray: [0, 0, 0]
        },
        legend: {
          show: false
        },
        tooltip: {
          shared: true,
          intersect: false,
          custom: ({series, dataPointIndex, w}) => {
            let html = '';
            const self = this;
            const items = []
            w.config.series.forEach(function (item, index) {
              const value = series[index][dataPointIndex];
              const timestamp = Object.keys(self.chartData[item.name])[dataPointIndex];
              if (value !== undefined && value !== null) {

                items.push({
                  name: item.name,
                  timestamp,
                  value
                })
              }
            });
            items.reverse().forEach((item) => {
              html += '<div class="apexcharts-tooltip-custom apexcharts-tooltip-custom-' + item.name + '">' +
                  '<span class="marker-dot"></span>' +
                  '<span class="timestamp">' +
                  self.$dayjs.unix(item.timestamp).format("llll") + '' +
                  ':</span><span class="value">' +
                  self.$options.filters.format(item.value, ValueFormats[self.selectedKpi].kpi)  + '</span></div>';
            })
            return html;
          }
        },
        decimalsInFloat: 0,
        axisTicks: {
          show: false
        }
      }
    },
  },
  methods: {
    async getChartData(forceUnit){
      let variables = {}
      this.unit = this.unit === 1 ? this.oldUnit : this.unit
      this.oldUnit = this.unit === 1 ? this.oldUnit : this.unit
      this.loaded = false

      let query = NET_SALES
      switch (this.selectedKpi) {
        case 'netSales':
          query = NET_SALES;
          break
        case 'adSpendPerNetSales':
          query = AD_SPEND_PER_NET_SALES;
          break
        case 'grossProfit':
          query = GROSS_PROFIT;
          break
        case 'orders':
          query = ORDERS;
          break
        case 'aov':
          query = AOV;
          break
        case 'sessions':
          query = SESSIONS;
          break
        case 'conversionRate':
          query = CONVERSION_RATE;
          break
        case 'adSpend':
          query = AD_SPEND;
          break
        case 'adSpendPerOrder':
          query = AD_SPEND_PER_ORDER;
          break
        case 'conversionRateOfAds':
          query = CONVERSION_RATE_OF_ADS;
          break
        case 'netSalesFromAds':
          query = NET_SALES_FROM_ADS;
          break
        case 'ordersFromAds':
          query = ORDERS_FROM_ADS;
          break
        case 'roas':
          query = ROAS;
          break
        case 'sessionsFromAds':
          query = SESSIONS_FROM_ADS;
          break
      }

      const onlyOneDayKpis = ['adSpend', 'adSpendPerOrder', 'adSpendPerNetSales', 'roas', 'sessionsFromAds', 'grossProfit']
      const start = Number(this.$dayjs(this.$store.state.selectedPeriod.startDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
      const end = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
      switch (this.selectedMainKpi) {
        case 'selected':
          if (forceUnit) {
            this.unit = end - start > 7776000 ? 4 : 2
          }
          variables = {
            selected: {
              projectId: Number(this.$route.params.projectId),
              dateStart: start,
              dateEnd: end,
              interval: Number(this.unit)
            },
            previous: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.previousPeriod.startDate).add(this.$dayjs(this.$store.state.previousPeriod.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.previousPeriod.endDate).add(this.$dayjs(this.$store.state.previousPeriod.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            aYearAgo: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.selectedPeriod.startDate).subtract(1, 'year').add(this.$dayjs().utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).subtract(1, 'year').add(this.$dayjs().utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            }
          }
          break;
        case 'yesterday':
          this.unit = onlyOneDayKpis.includes(this.selectedKpi) ? 2 : 1
          variables = {
            selected: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            previous: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.aWeekAgo.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.aWeekAgo.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.aWeekAgo.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.aWeekAgo.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            aYearAgo: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.startDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.endDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.yesterday.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            }
          }
          break;
        case 'thisWeek':
          if (forceUnit) {
            this.unit = 2
          }
          variables = {
            selected: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            previous: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastWeek.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastWeek.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastWeek.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastWeek.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            aYearAgo: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.startDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.endDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisWeek.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            }
          }
          break;
        case 'thisMonth':
          if (forceUnit) {
            this.unit = 2
          }
          variables = {
            selected: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            previous: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastMonth.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastMonth.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastMonth.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastMonth.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            aYearAgo: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.startDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.endDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisMonth.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            }
          }
          break;
        case 'thisYear':
          if (forceUnit) {
            this.unit = 4
          }
          variables = {
            selected: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            previous: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastYear.startDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastYear.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.lastYear.endDate).add(this.$dayjs(this.$store.state.mainKpiPeriods.lastYear.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            },
            aYearAgo: {
              projectId: Number(this.$route.params.projectId),
              dateStart: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.startDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.startDate).utcOffset(), 'minute').format('X')),
              dateEnd: Number(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.endDate).subtract(1, 'year').add(this.$dayjs(this.$store.state.mainKpiPeriods.thisYear.endDate).utcOffset(), 'minute').format('X')),
              interval: Number(this.unit)
            }
          }
          break;
      }

      let chartData = {}

      for (const [key, value] of Object.entries(variables)) {
        chartData[key] = await this.$apollo.query({
          query,
          variables: value,
          fetchPolicy: 'network-only',
          loadingKey: 'loading',
          error(error) {
            console.error("We've got an error!", error)
          }
        }).then((response) => {
          let chartData = {}
          response.data.dashboardChart[this.selectedKpi].forEach((element) => {
            chartData[this.$dayjs(element.breakdown).format('X')] = element.value
          })
          this.chartType = response.data.dashboardChart[this.selectedKpi].length === 1 ? 'bar' : 'area'
          return chartData
        })
      }

      return chartData
    },
    updateSeriesByPeriod() {
      this.series.forEach((element) => {
        if (this.visiblePeriods.includes(element.name)) {
          if(this.$refs.performanceChart !== undefined){
            this.$refs.performanceChart.showSeries(element.name)
          }
        } else {
          if(this.$refs.performanceChart !== undefined){
            this.$refs.performanceChart.hideSeries(element.name)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>