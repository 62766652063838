<template>
  <div>
    <v-app-bar
        app
        color="white"
        elevate-on-scroll
        shrink-on-scroll
        min-height="75"
        height="90"
        class="menu-app-bar"
    >
      <v-toolbar-title class="page-heading">
        {{ $t('header.dashboard') }}
        <div class="subheading"><a :href="$t('header.link')" target="_blank">{{ $t('header.howThisReportWork') }}</a></div>
      </v-toolbar-title>
      <v-col class="d-flex flex-row justify-end">
        <DatePicker/>
        <ProjectSelect/>
      </v-col>
    </v-app-bar>
    <v-navigation-drawer
        class="side-menu"
        color="#623c9f"
        fixed
        app
        mobile-breakpoint="767"
        width="64px"
    >
      <v-divider></v-divider>
      <v-list
          nav
          dense
          v-for="(menuItem, key) in sideMenu"
          :key="key"
      >
        <v-list-item
            link
            :to="menuItem.href && $route.params.projectId !== undefined ? menuItem.href : '#'"
            :title="menuItem.title"
            :style="$route.params.projectId === undefined ? 'cursor: wait;' : 'cursor: pointer;'"
        >
          <v-img
              :src="menuItem.logo"
              class="my-3"
              contain
              :height="menuItem.height ? menuItem.height : 30"
              :alt="menuItem.title"
          />
        </v-list-item>
      </v-list>
      <v-list nav dense style="position: fixed; bottom: 0; width: 100%">
        <v-list-item
            link
            :to="$route.params.projectId !== undefined ? {
            name: 'CustomExpenses',
            params: { projectId: this.$route.params.projectId },
          }: '#'"
            :title="$t('menu.expenses')"
            :style="$route.params.projectId === undefined ? 'cursor: wait;' : 'cursor: pointer;'"
        >
          <v-img
              :src="expenseIcon"
              class="my-3"
              contain
              height="30"
              :alt="$t('menu.expenses')"
          />
        </v-list-item>
        <v-menu
            top
            offset-x
            close-on-click
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
            >
              <v-img
                  :src="require('@/assets/icons/profile.svg')"
                  class="my-3"
                  contain
                  height="30"
              />
            </v-list-item>
          </template>
          <div class="dropdown-menu profile-menu-dropdown-menu">
            <ul class="list-unstyled">
              <li class="col-sm-12 fs-13 pt-1 pb-3">
                <div class="profile-menu-email text-left">
                  <strong class="d-block">{{ $t('profileMenu.greeting', { name: userData.name}) }}</strong>
                  <span class="profile-menu-email-block">{{ userData.email }}</span>
                </div>
              </li>
              <li class="col-sm-12 subscription-info" style="line-height: 8px">
                <div class="profile-menu-dropdown-item fs-11 d-flex align-items-center justify-content-start">
                  <span
                      class="text-gray flex-fill border-right package-inform-row">
                    <span>{{ $t('profileMenu.plan') }}:</span>
                    <strong> {{ userData.subscriptionAccount.activeSubscription.plan.name }}</strong>
                  </span>
                  <span v-if="this.$store.state.selectedProject.projectRole !== 'FREE'" class="text-gray flex-fill text-right package-inform-row">
                    <span>{{ $t('profileMenu.expirationDate') }}:</span>
                    <strong class="text-nowrap"> {{ userData.subscriptionAccount.activeSubscription.expirationDate * 1000 |dayjs('llll') }}</strong>
                  </span>
                  <div style="clear: both"></div>
                </div>
              </li>
              <li>
                <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
                   href="https://my.conversific.com/account/subscription"
                   title="$t('profileMenu.subscription')">
                  {{ $t('profileMenu.subscription') }}
                </a>
              </li>
              <li>
                <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
                   href="https://my.conversific.com/account/profile"
                   :title="$t('profileMenu.settings')">
                  {{ $t('profileMenu.settings') }}
                </a>
              </li>
              <li class="border-top">
                <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
                   href="https://docs.conversific.com/"
                   target="_blank"
                   :title="$t('profileMenu.help')">
                  {{ $t('profileMenu.help') }}
                </a>
              </li>
              <li>
                <router-link class="dropdown-item profile-menu-dropdown-item col-sm-12 text-danger"
                   :to="{name: 'Logout'}"
                   :title="$t('profileMenu.logout')">
                  {{ $t('profileMenu.logout') }}
                </router-link>
              </li>
            </ul>
          </div>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
    <div class="mobile-toolbar v-toolbar" style="
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    z-index: 6;
    background-color: #ffffff;">
      <div class="d-flex flex-row align-baseline" style="background-color: #623c9f; padding-bottom: 10px">
        <div style="
        position: relative;
        top: 5px;
        left: 4px;
        width: 25px;
        display: flex;
        margin: 0 10px;
        justify-content: center;
        ">
        <v-app-bar-nav-icon @click.stop="mobileMenu = !mobileMenu" class="menu-app-bar-icon" style="color: #D8C0FF"/>
          </div>
        <ProjectSelect/>
        <div style="
        position: relative;
        top: 12px;
        width: 50px;
        display: flex;
        margin: 0 10px;
        justify-content: center;
        "
             @click.stop="mobileProfileMenu = !mobileProfileMenu">
          <img
              :src="require('@/assets/icons/profile.svg')"
          />
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <DatePicker/>
      </div>
    </div>
    <v-navigation-drawer
        v-model="mobileMenu"
        color="#623c9f"
        fixed
        app
        temporary
    >
      <v-img
          :src="require('@/assets/logoWithTitle.svg')"
          class="my-3"
          contain
          height="30"
      />
      <v-list
          nav
          dense
          class="d-flex flex-column align-start mobile-menu-list"
      >
        <span
            v-for="(menuItem, key) in mobileMenuElements"
            :key="key"
            style="width: 100%"
        >
          <v-list-item link :to="menuItem.href && $route.params.projectId !== undefined ? menuItem.href : '#'" :title="menuItem.title">
            <v-img
                :src="menuItem.logo"
                class="my-3"
                contain
                :height="menuItem.height ? menuItem.height : 30"
                width="30"
                style="margin-right: 10px"
            />
            <span class="menu-title">
            {{ menuItem.title }}
            </span>
          </v-list-item>
        </span>
        <v-list-item
            link
            :to="$route.params.projectId !== undefined ? {
            name: 'CustomExpenses',
            params: { projectId: this.$route.params.projectId },
          }: '#'"
            :title="$t('menu.expenses')"
            :style="$route.params.projectId === undefined ? 'cursor: wait;' : 'cursor: pointer;'"
        >
          <v-img
              :src="expenseIcon"
              class="my-3"
              contain
              height="30"
              :alt="$t('menu.expenses')"
              style="margin-right: 5px"
          />
          <span class="menu-title">
            {{ $t('menu.expenses') }}
          </span>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
        v-model="mobileProfileMenu"
        color="#ffffff"
        fixed
        app
        temporary
        right
    >
      <ul class="list-unstyled">
        <li class="col-sm-12 fs-13 pt-1 pb-3">
          <div class="profile-menu-email text-left">
            <strong class="d-block">{{ $t('profileMenu.greeting', { name: userData.name}) }}</strong>
            <span class="profile-menu-email-block">{{ userData.email }}</span>
          </div>
        </li>
        <li class="col-sm-12 subscription-info" style="line-height: 8px">
          <div class="profile-menu-dropdown-item fs-11 d-flex align-items-center justify-content-start">
            <span class="text-gray flex-fill border-right package-inform-row">
              <span>{{ $t('profileMenu.plan') }}:</span>
              <strong> {{ userData.subscriptionAccount.activeSubscription.plan.name }}</strong>
            </span>
            <span class="text-gray flex-fill text-right package-inform-row" v-if="selectedProject.projectRole !== 'FREE'">
              <span>{{ $t('profileMenu.expirationDate') }}:</span>
              <strong class="text-nowrap"> {{ userData.subscriptionAccount.activeSubscription.expirationDate * 1000 |dayjs('llll') }}</strong>
            </span>
            <div class="clearfix"></div>
          </div>
        </li>
        <li>
          <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
             href="https://my.conversific.com/account/subscription"
             title="$t('profileMenu.subscription')">
            {{ $t('profileMenu.subscription') }}
          </a>
        </li>
        <li>
          <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
             href="https://my.conversific.com/account/profile"
             :title="$t('profileMenu.settings')">
            {{ $t('profileMenu.settings') }}
          </a>
        </li>
        <li class="border-top">
          <a class="dropdown-item profile-menu-dropdown-item col-sm-12"
             href="https://docs.conversific.com/"
             target="_blank"
             :title="$t('profileMenu.help')">
            {{ $t('profileMenu.help') }}
          </a>
        </li>
        <li>
          <router-link class="dropdown-item profile-menu-dropdown-item col-sm-12 text-danger"
                       to="/logout"
                       :title="$t('profileMenu.logout')">
            {{ $t('profileMenu.logout') }}
          </router-link>
        </li>
      </ul>
    </v-navigation-drawer>
    <setting-dialog :dialog="modal" @close="modal = false"></setting-dialog>
  </div>
</template>

<script>
import ProjectSelect from "./header/ProjectSelect";
import DatePicker from "./header/DatePicker";
import logo from "@/assets/logo.svg"
import dashboardIcon from "@/assets/icons/dashboard.svg"
import reportIcon from "@/assets/icons/report.svg"
import benchmarkIcon from "@/assets/icons/benchmark.svg"
import insightsIcon from "@/assets/icons/insights.svg"
import segmentsIcon from "@/assets/icons/segments.svg"
import expenseIcon from "@/assets/icons/expense.svg"
import {mapGetters} from "vuex"
import SettingDialog from "./header/SettingDialog";

export default {
  name: "Header",
  components: {SettingDialog, DatePicker, ProjectSelect},
  data() {
    return {
      modal: false,
      mobileMenu: false,
      mobileProfileMenu: false,
      expenseIcon: expenseIcon,
      sideMenu: {
        logo: {
          href: false,
          logo: logo,
        },
        dashboard: {
          href: {
            name: 'Dashboard',
            params: { projectId: this.$route.params.projectId },
          },
          title: this.$t('menu.dashboard'),
          logo: dashboardIcon,
        },
        reports: {
          href: {
            name: 'Reports',
            params: { projectId: this.$route.params.projectId },
          },
          title: this.$t('menu.reports'),
          logo: reportIcon,
        },
        benchmark: {
          href: {
            name: 'Benchmark',
            params: { projectId: this.$route.params.projectId },
          },
          title: this.$t('menu.benchmark'),
          logo: benchmarkIcon,
          height: 20
        },
        insights: {
          href: {
            name: 'Insights',
            params: { projectId: this.$route.params.projectId },
          },
          title: this.$t('menu.insights'),
          logo: insightsIcon,
        },
        segments: {
          href: {
            name: 'Segments',
            params: { projectId: this.$route.params.projectId },
          },
          title: this.$t('menu.segments'),
          logo: segmentsIcon,
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedProject: 'getSelectedProject',
      userData: 'getUserData',
    }),
    mobileMenuElements() {
      let mobileElements = {...this.sideMenu};
      delete mobileElements.logo;
      return mobileElements
    }
  },
  watch: {
    '$route.params'(toParams) {
      Object.values(this.sideMenu).forEach((element) => {
        if (element.href) {
          element.href = {
            name: element.href.name,
            params: { projectId: toParams.projectId },
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>