<template>

  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-col>
        <v-btn
            class="section-button text-capitalize"
            color="secondary"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            small
        >
          {{ $t('overview.customize') }}
        </v-btn>
      </v-col>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" style="justify-content: space-between!important;">
        {{ $t('overview.modal.customize.title') }}
        <span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text class="pt-3" style="position: relative">
        <strong>
          {{ $t('overview.modal.customize.text1') }}
        </strong>
        <br/>
        {{ $t('overview.modal.customize.text2') }}
        <v-row class="my-6 px-2">
          <template v-for="tile in Object.keys(allTiles)">
            <v-col
                :key="tile"
                cols="6"
                class="pa-0"
            >
              <v-checkbox
                  v-model="selectedTiles"
                  :label="$t('kpiNames.' + tile)"
                  :value="tile"
                  class="pa-0"
                  dense
                  hide-details
                  :disabled="selectedTiles.length >= 10 && selectedTiles.indexOf(tile) === -1"
                  @click="notEnough=false"
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>
        <v-row v-show="notEnough">
          <v-col>
            <span class="text-danger">
              {{ $t('overview.modal.customize.notEnough') }}
            </span>
          </v-col>
        </v-row>
        <div class="overview-overlay" v-if="this.$store.state.selectedProject.projectRole === 'FREE'">
          <div class="text">
            {{ $t('common.notAvailable') }}
          </div>
          <router-link
              class="btn-upgrade"
              :to="{name: 'Subscription'}"
          >
            {{ $t('common.upgrade') }}</router-link>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end pr-9 pb-5" v-if="this.$store.state.selectedProject.projectRole !== 'FREE'">
        <v-btn
            color="error"
            outlined
            small
            class="mr-2"
            @click="dialog = false"
        >
          {{ $t('overview.modal.customize.cancel') }}
        </v-btn>
        <v-btn
            color="primary"
            small
            @click="saveTiles()"
        >
          {{ $t('overview.modal.customize.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tiles from "@/config/overview/tiles.json"
import SET_SETTING from "@/graphql/settings/setSetting.gql";

export default {
  name: "CustomizeOverview",
  data() {
    return {
      dialog: false,
      allTiles: tiles,
      selectedTiles: [],
      notEnough: false
    }
  },
  computed: {
    tiles: {
      get() {
        return this.$store.state.dashboard.overviewTiles
      },
      set (value) {
        this.$store.commit('setTiles', value)
      }
    }
  },
  watch: {
    dialog() {
      this.selectedTiles = this.tiles
    }
  },
  methods: {
    saveTiles() {
      if (this.selectedTiles.length < 10) {
        this.notEnough = true
        return
      }
      const variables = {
        projectId: Number(this.$route.params.projectId),
        settingKey: 'overview',
        settingValue: JSON.stringify(this.selectedTiles),
      }
      this.$apollo.mutate({
        mutation: SET_SETTING,
        variables
      }).then((response) => {
        if (response.data.setSetting) {
          this.tiles = this.selectedTiles
          this.dialog = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>