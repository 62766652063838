<template>
  <v-row
      v-if="
          Object.keys(data.selectedPeriod).length > 0">
    <v-col cols="6"
           v-for="(value, kpi) in data.selectedPeriod"
           :key="kpi">
      <v-card
          class="expense-card"
          height="70"
          :ripple="false"
      >
        <span>
        <div class="expense-title">
          <span class="expense-badge rounded-circle" :style="'background-color: ' + colors[kpi] + '!important'"></span>
          {{ $t('kpiNames.' + kpi) }}
        </div>
        <div class="value">{{ value | format(ValueFormats[kpi]['kpi'] || 'number') }}</div>
        <div class="growth" style="color: #cccccc">
          {{ diffInPercent(value, data.previousPeriod[kpi]) | format("grayGrowth") }}
        </div>
        </span>
      </v-card>
    </v-col>
  </v-row>
  <kpi-skeleton v-else />
</template>

<script>
import ValueFormats from "@/config/ValueFormats.json"
import KpiSkeleton from "./KpiSkeleton";
export default {
  name: "ExpenseKpi",
  components: {KpiSkeleton},
  data() {
    return {
      ValueFormats
    }
  },
  props: {
    data: {
      type: Object
    },
    colors: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>