<template>
  <v-item-group
      mandatory
      v-model="selectedMainKpi"
  >
    <v-row class="d-flex flex-column"
           v-if="this.$store.state.selectedProject.projectRole !== 'FREE'"
    >
      <v-col
          v-for="(interval, index) in intervals"
          :key="index"
          class="main-kpi-item-holder"
      >
        <v-item v-slot="{ active, toggle }">
          <v-card
              :class="active ? 'active' : ''"
              class="main-kpi-item"
              min-height="76"
              @click="toggle"
              :ripple="false"
          >
            <v-row
                v-if="
                Object.keys(kpiData[interval[0].key]).length > 0 &&
                Object.keys(kpiData[interval[1].key]).length > 0"
            >
              <v-col sm="6" class="pb-1">
                <div class="title">
                  {{ interval[0].title }}
                </div>
                <div v-if="index === 0" class="date">
                  {{ $store.state.selectedPeriod.startDate|dayjs('llll') }} - {{ $store.state.selectedPeriod.endDate|dayjs('llll') }}
                </div>
                <div v-else-if="index === 1" class="date">
                  {{ $store.state.mainKpiPeriods[interval[0].key].startDate|dayjs('llll') }}
                </div>
                <div v-else class="date">
                  {{ $store.state.mainKpiPeriods[interval[0].key].startDate|dayjs('llll') }} - {{ $store.state.mainKpiPeriods[interval[0].key].endDate|dayjs('llll') }}
                </div>
                <v-row class="kpi-content">
                  <div class="value">
                    {{ kpiData[interval[0].key][selectedKpi] | format(formatType) }}
                  </div>
                  <growth class="growth"
                          :value="diffInPercent(kpiData[interval[0].key][selectedKpi], kpiData[interval[1].key][selectedKpi])"
                          :negative="selectedKpi ? ValueFormats[selectedKpi].negativeGrowth || false : false"/>
                </v-row>
              </v-col>
              <v-col sm="6" class="text-sm-right pb-1">
                <div class="title">
                  {{ interval[1].title }}
                </div>
                <div v-if="index === 0" class="date">
                  {{ $store.state.previousPeriod.startDate|dayjs('llll') }} - {{ $store.state.previousPeriod.endDate|dayjs('llll') }}
                </div>
                <div v-else-if="index === 1" class="date">
                  {{ $store.state.mainKpiPeriods[interval[1].key].startDate|dayjs('llll') }}
                </div>
                <div v-else class="date">
                  {{ $store.state.mainKpiPeriods[interval[1].key].startDate|dayjs('llll') }} - {{ $store.state.mainKpiPeriods[interval[1].key].endDate|dayjs('llll') }}
                </div>
                <div class="kpi-content-right">
                  <div class="value">
                    {{ kpiData[interval[1].key][selectedKpi] | format(formatType) }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <main-kpi-skeleton v-else />
          </v-card>
        </v-item>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column" v-else>
      <v-col class="main-kpi-item-holder">
        <v-item v-slot="{ active, toggle }">
          <v-card
              :class="active ? 'active' : ''"
              class="main-kpi-item"
              min-height="76"
              @click="toggle"
              :ripple="false"
          >
            <v-row
                v-if="
                  Object.keys(kpiData.selected).length > 0 &&
                  Object.keys(kpiData.previous).length > 0"
            >
              <v-col sm="6" class="pb-1">
                <div class="title">
                  {{ $t('dateRanges.selected')}}
                </div>
                <div class="date">
                  {{ $store.state.selectedPeriod.startDate|dayjs('llll') }} - {{ $store.state.selectedPeriod.endDate|dayjs('llll') }}
                </div>
                <v-row class="kpi-content">
                  <div class="value">
                    {{ kpiData.selected[selectedKpi] | format(formatType) }}
                  </div>
                  <growth class="growth"
                          :value="diffInPercent(kpiData.selected[selectedKpi], kpiData.selected[selectedKpi])"
                          :negative="selectedKpi ? ValueFormats[selectedKpi].negativeGrowth || false : false"/>
                </v-row>
              </v-col>
              <v-col sm="6" class="text-sm-right pb-1">
                <div class="title">
                  {{ $t('dateRanges.previous') }}
                </div>
                <div class="date">
                  {{ $store.state.previousPeriod.startDate|dayjs('llll') }} - {{ $store.state.previousPeriod.endDate|dayjs('llll') }}
                </div>
                <div class="kpi-content-right">
                  <div class="value">
                    {{ kpiData.previous[selectedKpi] | format(formatType) }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <main-kpi-skeleton v-else />
          </v-card>
        </v-item>
      <div style="position: relative">
        <v-item>
          <v-card
              class="main-kpi-item"
              min-height="76"
              :ripple="false"
          >
            <main-kpi-skeleton />
          </v-card>
        </v-item>
        <v-item>
          <v-card
              class="main-kpi-item"
              min-height="76"
              :ripple="false"
          >
            <main-kpi-skeleton />
          </v-card>
        </v-item>
        <v-item>
          <v-card
              class="main-kpi-item"
              min-height="76"
              :ripple="false"
          >
            <main-kpi-skeleton />
          </v-card>
        </v-item>
        <v-item>
          <v-card
              class="main-kpi-item"
              min-height="76"
              :ripple="false"
          >
            <main-kpi-skeleton />
          </v-card>
        </v-item>
        <div class="overlay" v-if="this.$store.state.selectedProject.shop_type == 'shoprenter'">
          <div class="text" >
            {{ $t('common.notAvailableSR') }}
          </div>
          <router-link
              class="btn-upgrade"
              :to="{name: 'Subscription'}"
          >
            {{ $t('common.upgradeSR') }}
          </router-link>
        </div>
        <div class="overlay" v-else>
          <div class="text" >
            {{ $t('common.notAvailable') }}
          </div>
          <router-link
              class="btn-upgrade"
              :to="{name: 'Subscription'}"
          >
            {{ $t('common.upgrade') }}
          </router-link>
        </div>
      </div>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
import Growth from "@/components/common/growth";
import ValueFormats from "@/config/ValueFormats.json";
import MainKpiSkeleton from "./MainKpiSkeleton";

export default {
  name: "MainKpi",
  components: {
    MainKpiSkeleton,
    Growth
  },
  props: {
    kpiData: {
      type: Object
    },
    selectedKpi: {
      type: String
    },
  },
  computed: {
    formatType() {
      if (this.selectedKpi) {
        return ValueFormats[this.selectedKpi].mainKpi
      }
      return 'number'
    }
  },
  data() {
    return {
      selectedMainKpi: null,
      ValueFormats,
      intervals: [
        [
          {
            title: this.$t('dateRanges.selected'),
            key: 'selected'
          },
          {
            title: this.$t('dateRanges.previous'),
            key: 'previous'
          }
        ],
        [
          {
            title: this.$t('dateRanges.yesterday'),
            key: 'yesterday'
          },
          {
            title: this.$t('dateRanges.aWeekAgo', {weekday: this.$dayjs().subtract(1, 'day').format('dddd')}),
            key: 'aWeekAgo'
          }
        ],
        [
          {
            title: this.$t('dateRanges.thisWeek'),
            key: 'thisWeek'
          },
          {
            title: this.$t('dateRanges.lastWeek'),
            key: 'lastWeek'
          }
        ],
        [
          {
            title: this.$t('dateRanges.thisMonth'),
            key: 'thisMonth'
          },
          {
            title: this.$t('dateRanges.lastMonth'),
            key: 'lastMonth'
          }
        ],
        [
          {
            title: this.$t('dateRanges.thisYear'),
            key: 'thisYear'
          },
          {
            title: this.$t('dateRanges.lastYear'),
            key: 'lastYear'
          }
        ],
      ]
    }
  },
  watch: {
    selectedMainKpi() {
      this.$emit('mainKpiSelect', this.intervals[this.selectedMainKpi][0].key)
    }
  }
}
</script>

<style scoped>

</style>