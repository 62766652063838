<template>
  <v-select
      v-model="selectedProject"
      :items="projects"
      item-value="id"
      dense
      solo
      hide-details
      flat
      class="projectSelect"
      @change="projectSelect"
  >
    <template v-slot:selection="{ item }">
      <img v-if="item.url" :src="'//unavatar.now.sh/' + item.url.replace(/^https?:\/\//, '')" width="10" height="10" class="mr-2">
      {{ item.name }}
    </template>
    <template v-slot:item="{ item }">
      <img v-if="item.url" :src="'//unavatar.now.sh/' + item.url.replace(/^https?:\/\//, '')" width="10" height="10" class="mr-2"> {{ item.name }}
    </template>
    <template v-slot:append-item>
      <div class="add-new-project-holder">
        <div class="add-new-project">
          <router-link
              :to="{name: 'ProjectCreate'}"
              style="text-decoration: none"
          >
            <i class="add-new-project-plus">+</i>{{ $t('common.create') }}</router-link>
          <span v-if="userRole === 'FREE'" class="pro-feature d-flex align-items-center justify-content-center">
          <router-link
              class="btn-upgrade"
              :to="{name: 'Subscription'}"
          >
            {{ $t('common.upgrade') }}</router-link>
        </span>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "ProjectSelect",
  data() {
    return {
      selectedProject: this.$route.params.projectId,
    }
  },
  computed: {
    projects() {
      let projects = [];
      const userData = this.$store.getters.getUserData;
      projects = userData.projects;
      const self = this;
      const selectedProject = userData.projects[userData.projects.findIndex(function (project) {
        return project.id === self.$route.params.projectId
      })]
      if(selectedProject === undefined) {
        const selectedProjectData = this.$store.getters.getSelectedProject
        if (selectedProjectData.id !== undefined) {
          projects.push(selectedProjectData)
        }
      }
      return projects
    },
    userRole: {
      get() {
        return this.$store.state.selectedProject.projectRole
      },
    }
  },
  updated() {
    this.selectedProject = this.$route.params.projectId
  },
  methods: {
    projectSelect(){
      if (this.selectedProject > 0 && this.selectedProject !== this.$route.params.projectId) {
        this.$router.push({name: this.$route.name, params: {projectId: this.selectedProject}});
      }
    },
  }
}
</script>

<style scoped>

</style>