import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Hotjar from 'vue-hotjar'
import i18n from "./plugins/i18n"
import dayjs from "./plugins/dayjs"
import title from "./plugins/title"
import FlagIcon from 'vue-flag-icon'
import * as Sentry from "@sentry/vue"
import VueIntercom from 'vue-intercom'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import formatter from "./plugins/formatter"
import { createProvider } from './vue-apollo'
import { BrowserTracing } from "@sentry/tracing"
import VueSkeletonLoader from 'skeleton-loader-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faAngleUp, faCircleInfo, faLightbulb, faHandPointRight } from '@fortawesome/free-solid-svg-icons'
Vue.config.productionTip = false
library.add(faCalendarAlt)
library.add(faAngleDown)
library.add(faAngleUp)
library.add(faCircleInfo)
library.add(faLightbulb)
library.add(faHandPointRight)
Vue.mixin(title)

Vue.use(FlagIcon)
Vue.use(formatter)
Vue.use(VueApexCharts)
Vue.use(dayjs, { locale: i18n.locale })
Vue.use(VueIntercom, {
  appId: process.env.VUE_APP_INTERCOM_APP_ID
})
if (process.env.VUE_APP_HOTJAR_ENABLED === "true") {
  Vue.use (Hotjar, {
    id: process.env.VUE_APP_HOTJAR_APP_ID,
    isProduction: true,
  })
}
Vue.component('apexchart', VueApexCharts)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('skeleton-loader', VueSkeletonLoader)

if (process.env.VUE_APP_SNETRY_ENABLED === "true") {
  Sentry.init({
    Vue,
    dsn: "https://3645d8dbe53041b4bb32d29105eb0525@o0.ingest.sentry.io/0",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.conversific.com", /^\//],
      }),
    ],
    tracesSampleRate: 0.3,
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')