<template>
  <div class="module">
    <v-row>
      <template v-for="tile in tiles">
        <tile :key="tile" :kpi="tile"/>
      </template>
    </v-row>
  </div>
</template>

<script>
import Tile from "./overview/Tile";
import GET_SETTINGS from "@/graphql/settings/getSettings.gql"

export default {
  name: "Overview",
  components: {Tile},
  apollo: {
    overviewSetting: {
      query: GET_SETTINGS,
      manual: true,
      variables () {
        return this.variables
      },
      result ({data}) {
        if (Object.keys(data.settings.settings).includes('project') &&
            Object.keys(data.settings.settings.project).includes('overview')) {
          this.tiles = JSON.parse(data.settings.settings.project.overview)
        }
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
  },
  computed: {
    tiles: {
      get() {
        return this.$store.state.dashboard.overviewTiles
      },
      set (value) {
        this.$store.commit('setTiles', value)
      }
    },
    variables() {
      return {
        projectId: Number(this.$store.state.selectedProject.id),
        settingKey: 'overview'
      }
    },
  }
}
</script>

<style scoped>

</style>