var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.data.selectedPeriod).length > 0)?_c('v-slide-group',{staticClass:"kpi-holder",attrs:{"mandatory":"","show-arrows":""},model:{value:(_vm.selectedKpi),callback:function ($$v) {_vm.selectedKpi=$$v},expression:"selectedKpi"}},_vm._l((_vm.data.selectedPeriod),function(value,kpi){return _c('v-slide-item',{key:kpi,staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"kpi-item",class:active ? 'active' : '',attrs:{"ripple":false},on:{"click":toggle}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#fff"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"inner"},'div',attrs,false),on),[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('kpiNames.' + kpi))+" ")]),_c('v-row',{staticClass:"kpi-content"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("format")(value,_vm.ValueFormats[kpi]['kpi'] || 'number')))]),_c('growth',{staticClass:"growth",attrs:{"value":_vm.diffInPercent(value, _vm.data.previousPeriod[kpi]),"negative":_vm.ValueFormats[kpi].negativeGrowth || false}})],1)],1)]}}],null,true)},[_c('div',{staticClass:"kpiRow-tooltip"},[_vm._v(" "+_vm._s(_vm.$t('kpiTooltip.' + kpi))+" ")])])],1)]}}],null,true)})}),1):_c('v-slide-group',{staticClass:"kpi-holder",attrs:{"mandatory":"","show-arrows":""}},_vm._l((7),function(n){return _c('v-slide-item',{key:n,staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"kpi-item",class:active ? 'active' : '',attrs:{"ripple":false},on:{"click":toggle}},[_c('kpi-skeleton')],1)]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }