<template>
  <date-range-picker
      ref="picker"
      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
      :maxDate="this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')"
      showDropdowns
      autoApply
      v-model="dateRange"
      @select="finishSelection"
      :class="this.$store.state.selectedProject.projectRole === 'FREE' ? 'daterangepicker-for-free-user' : ''"
  >
    <template #input="picker">
      <font-awesome-icon :icon="['far', 'calendar-alt']"/>
      <span class="segmentTextInterval report-range-text">{{ $t('dateRanges.' + periodType) }}</span>
      <span class="segmentDateInterval report-range-date">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
    </template>
    <template #ranges="ranges">
      <div class="ranges">
        <ul>
          <li v-for="(range, name) in dateRanges" :key="name" @click="[ranges.clickRange(range), rangeClick(name)]" :class="getRangeClasses(name)">
            {{ $t('dateRanges.' + name) }}
          </li>
        </ul>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import dayjs from 'dayjs'
export default {
  name: "DatePicker",
  components: {DateRangePicker},
  data() {
    return {
      dateRange: {
        startDate: this.$store.state.selectedPeriod.startDate,
        endDate: this.$store.state.selectedPeriod.endDate,
      },
      dateRanges: {
        last30Days: [
          this.$dayjs().startOf('day').subtract(30, 'day').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'day').toDate()
        ],
        thisMonth: [
          this.$dayjs().startOf('day').startOf('month').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'day').toDate()
        ],
        lastMonth: [
          this.$dayjs().startOf('day').subtract(1, 'month').startOf('month').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'month').endOf('month').toDate()
        ],
        thisWeek: [
          this.$dayjs().startOf('day').startOf('week').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'day').toDate()
        ],
        lastWeek: [
          this.$dayjs().startOf('day').subtract(1, 'week').startOf('week').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'week').endOf('week').toDate()
        ],
        thisYear: [
          this.$dayjs().startOf('day').startOf('year').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'day').toDate()
        ],
        lastYear: [
          this.$dayjs().startOf('day').subtract(1, 'year').startOf('year').toDate(),
          this.$dayjs().endOf('day').subtract(1, 'year').endOf('year').toDate()
        ]
      }
    }
  },
  methods: {
    getRangeClasses(range) {
      return this.periodType === range ? 'active' : (this.$store.state.selectedProject.projectRole === "FREE" ? 'disabled' : '');
    },
    finishSelection() {
      this.periodType = 'custom'
    },
    rangeClick(name) {
      this.periodType = name
      return true
    }
  },
  computed: {
    periodType: {
      get () {
        return this.$store.getters.getPeriodType
      },
      set (value) {
        this.$store.commit('setPeriodType', value)
      }
    },
  },
  watch: {
    dateRange() {
      this.dateRange.startDate = this.$dayjs(this.dateRange.startDate).startOf('day').toDate()
      this.dateRange.endDate = this.$dayjs(this.dateRange.endDate).endOf('day').toDate()
      this.$store.commit('changeSelectedPeriod', {
        type: this.periodType,
        range: this.dateRange
      })
    }
  },
  filters: {
    date (val) {
      return val ? dayjs(val).format("llll") : ''
    }
  }
}
</script>

<style scoped>

</style>