import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import ME_QUERY from "@/graphql/user/me.gql";
import PROJECT_INFORMATION from "@/graphql/user/projectInformation.gql";
import {apolloClient} from "@/vue-apollo";
import store from '@/store';
import dayjs from "dayjs";
import Cookies from 'js-cookie';
import AdvancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(AdvancedFormat)
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

Vue.use(VueRouter)

function getSelectedPeriod() {
    let selectedPeriod = {
        startDate: dayjs().subtract(30, 'day').startOf('day').toDate(),
        endDate: dayjs().subtract(1, 'day').endOf('day').toDate()
    }
    if (sessionStorage.getItem('selectedPeriod')) {
        selectedPeriod = JSON.parse(sessionStorage.getItem('selectedPeriod'))
    }

    return selectedPeriod
}

function setDateRangeCookie() {
    const selectedPeriod = getSelectedPeriod()
    Cookies.set('ConversificDateRange', JSON.stringify({
        'type': store.getters.getPeriodType,
        'selected': {
            startDate: dayjs(selectedPeriod.startDate).add(dayjs(selectedPeriod.startDate).utcOffset(), 'minute').format('X'),
            endDate: dayjs(selectedPeriod.endDate).add(dayjs(selectedPeriod.endDate).utcOffset(), 'minute').format('X')
        }
    }), { path: '/', domain: '.conversific.com' })
}

const routes = [
    {
        path: '/',
        name: 'Homepage',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            next({
                name: 'Dashboard',
                params: {projectId: store.state.userData.projects[store.state.userData.projects.length - 1]["id"]}
            })
        }
    },
    {
        path: '/dashboard/:projectId',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: "/reports/:projectId",
        name: 'Reports',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/reports/executive/' + to.params.projectId;
        }
    },
    {
        path: "/benchmark/:projectId",
        name: 'Benchmark',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/benchmark/index/' + to.params.projectId;
        }
    },
    {
        path: "/insights/:projectId",
        name: 'Insights',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/insights/index/' + to.params.projectId;
        }
    },
    {
        path: "/segments/:projectId",
        name: 'Segments',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/segments/order/' + to.params.projectId + '?type=all';
        }
    },
    {
        path: "/settings/general/:projectId",
        name: 'StoreSettings',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/settings/general/' + to.params.projectId;
        }
    },
    {
        path: "/account/subscription",
        name: 'Subscription',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/checkout/order';
        }
    },
    {
        path: "/project/create",
        name: 'ProjectCreate',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/project/create';
        }
    },
    {
        path: "/reports/profit/:projectId",
        name: 'ProfitReport',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/reports/profit/' + to.params.projectId;
        }
    },
    {
        path: "/settings/custom-expenses/:projectId",
        name: 'CustomExpenses',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            setDateRangeCookie()
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/settings/custom-expenses/' + to.params.projectId;
        }
    },
    {
        path: "/logout",
        name: 'Logout',
        // eslint-disable-next-line no-unused-vars
        beforeEnter(to, from, next) {
            Cookies.remove('ConversificAuthToken', { path: '/', domain: '.conversific.com' })
            window.location.href = process.env.VUE_APP_OLD_APP_URL + '/logout';
        }
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from,next) => {
    if (to.name !== "Dashboard") {
        next()
    }
    const data = await apolloClient
        .query({
            query: ME_QUERY,
        }).catch((e) => {
            if ((e.networkError &&
                e.networkError.statusCode === 500 &&
                e.networkError.result.errors[0].message === "Context creation failed: Token is invalid jwt") ||
                (e.graphQLErrors &&
                e.graphQLErrors[0] &&
                e.graphQLErrors[0].message === "Token is invalid"
                )
            ) {
                window.location.href = process.env.VUE_APP_OLD_APP_URL + '/dashboard/' + to.params.projectId
            }
        })
    const user = data.data.me;
    const localStorageLang = localStorage.getItem('lang');
    
    if (localStorageLang === null || (user.role === 'ROLE_USER' && localStorageLang !== user.locale)) {
        localStorage.setItem('lang', user.locale)
    }
    store.commit('setUserData', user);
    store.commit('setConversificRole', user.ownAccount.role);
    if (to.params.projectId) {
        const projectInformation = await apolloClient
            .query({
                query: PROJECT_INFORMATION,
                fetchPolicy: 'no-cache',
                variables: {
                    projectId: Number(to.params.projectId)
                }
            }).catch((e) => {
                if ((e.graphQLErrors &&
                    e.graphQLErrors[0].message === "ACCESS_DENIED_THIS_INFORMATION")
                ) {
                    next({
                        name: 'Dashboard',
                        params: {projectId: user.allProjects[user.allProjects.length - 1]["id"]}
                    })
                }
            })
        const selectedProject = projectInformation.data.selectedProjectInformation
        store.commit('setSelectedProject', selectedProject);
        if (selectedProject.projectRole === "FREE") {
            const payload = {
                range: {
                    startDate: dayjs().subtract(1, 'month').startOf('month').subtract(dayjs().utcOffset(), 'minute').toDate(),
                    endDate: dayjs().subtract(1, 'month').endOf('month').subtract(dayjs().utcOffset(), 'minute').toDate()
                },
                type: 'thisMonth'
            };
            store.commit("changeSelectedPeriod", payload);
        }
    }

    next()
})

export default router
