<template>
  <v-dialog
      v-model="dialog"
      width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-col>
        <v-btn
            class="section-button text-capitalize"
            color="secondary"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            small
        >
          {{ $t('goal.setGoal') }}
        </v-btn>
      </v-col>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('goal.title.' + $store.state.dashboard.goalSettings.type) }}
      </v-card-title>

      <v-card-text class="pt-3">
        {{ $t('goal.text') }}

        <v-divider class="mt-3 mb-3"></v-divider>

        <v-row class="d-flex align-baseline">
          <v-col class="text-no-wrap" style="width: 5px; padding-right: 0">
            {{ $t('goal.my') }}
          </v-col>
          <v-col cols="5" md="3">
            <v-select
                v-model="goalSettings.type"
                :items="goalIntervalTypes"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col cols="5" md="3">
            <v-select
                v-model="goalSettings.priceType"
                :items="goalTypes"
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col class="text-no-wrap text-center">
            {{ $t('goal.goalFor') }}
          </v-col>
          <v-col cols="7" md="4">
            <v-text-field
                v-model.number="goalSettings.value"
                :suffix="currencies[$store.state.selectedProject.currency_id].code || 'USD'"
                type="number"
                step="0.01"
                min="0"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
            color="primary"
            @click="setGoal"
            small
        >
          {{ $t('goal.setGoal') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SET_SETTING from "@/graphql/settings/setSetting.gql";
import currencies from "@/config/currencies.json"

export default {
  name: "GoalDialog",
  computed: {
    goalSettings: {
      get() {
        return this.$store.state.dashboard.goalSettings
      },
      set(value) {
        this.$store.commit('setGoalSettings', value)
      }
    }
  },
  data() {
    return {
      dialog: false,
      setGoalFlag: false,
      tmpSetting: {},
      currencies: currencies,
      goalTypes: [
        {
          value: 'grossSales',
          text: this.$t('goal.options.grossSales'),
        },
        {
          value: 'netSales',
          text: this.$t('goal.options.netSales'),
        },
        {
          value: 'grossProfit',
          text: this.$t('goal.options.grossProfit'),
        },
        {
          value: 'netProfit',
          text: this.$t('goal.options.netProfit'),
        }
      ],
      goalIntervalTypes: [
        {
          value: 'yearly',
          text: this.$t('goal.intervalType.yearly'),
        },
        {
          value: 'quarterly',
          text: this.$t('goal.intervalType.quarterly'),
        },
        {
          value: 'monthly',
          text: this.$t('goal.intervalType.monthly'),
        },
        {
          value: 'weekly',
          text: this.$t('goal.intervalType.weekly'),
        },
        {
          value: 'daily',
          text: this.$t('goal.intervalType.daily'),
        }
      ],
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.tmpSetting = {...this.goalSettings}
      } else {
        if (!this.setGoalFlag) {
          this.$store.commit('setGoalSettings', this.tmpSetting)
        } else {
          this.$store.commit('setGoalSettings', this.goalSettings)
        }
      }
    }
  },
  methods: {
    setGoal() {
      const variables = {
        projectId: Number(this.$route.params.projectId) || 0,
        settingKey: 'goal',
        settingValue: JSON.stringify(this.goalSettings),
      }
      this.$apollo.mutate({
        mutation: SET_SETTING,
        variables
      }).then(() => {
        this.setGoalFlag = true
        this.dialog = false
        setTimeout(() => {
          this.setGoalFlag = false
        }, 1000)
      })
    }
  }
}
</script>

<style scoped>

</style>