<template>
  <div class="module">
    <kpi-with-chart :data="kpiData" :mainKpiData="mainKpiData"/>
  </div>
</template>

<script>
import KpiWithChart from "@/components/common/KpiWithChart";
import PERFORMANCE_BREAKDOWN from "@/graphql/dashboard/performanceBreakdown.gql"
export default {
  name: "PerformanceBreakdown",
  components: {KpiWithChart},
  data() {
    return {
      kpiData: {
        selectedPeriod: {},
        previousPeriod:  {}
      },
      mainKpiData: {
        selected: {},
        previous:  {},
        yesterday: {},
        aWeekAgo: {},
        thisWeek: {},
        lastWeek: {},
        thisMonth: {},
        lastMonth: {},
        thisYear: {},
        lastYear: {},
      }
    }
  },
  async created() {
    await this.reCalculateData()
    if (this.$store.state.selectedProject.projectRole !== 'FREE') {
      await this.recalculateMainKpiData()
    }
  },
  watch: {
    '$store.state.selectedPeriod'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod:  {}
      }
      this.mainKpiData.selected = {}
      this.reCalculateData()
    },
    '$store.state.selectedProject'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod:  {}
      }
      this.mainKpiData= {
        selected: {},
        previous:  {},
        yesterday: {},
        aWeekAgo: {},
        thisWeek: {},
        lastWeek: {},
        thisMonth: {},
        lastMonth: {},
        thisYear: {},
        lastYear: {},
      }
      this.mainKpiData.selected = {}
      this.reCalculateData()
      if (this.$store.state.selectedProject.projectRole !== 'FREE') {
        this.recalculateMainKpiData()
      }
    }
  },
  methods: {
    async reCalculateData() {
      this.kpiData.selectedPeriod = await this.getKpiData(
          this.$store.state.selectedPeriod.startDate,
          this.$store.state.selectedPeriod.endDate
      )
      this.kpiData.previousPeriod = await this.getKpiData(
          this.$store.state.previousPeriod.startDate,
          this.$store.state.previousPeriod.endDate
      )
      this.mainKpiData.selected = this.kpiData.selectedPeriod
      this.mainKpiData.previous = this.kpiData.previousPeriod
    },
    async recalculateMainKpiData() {
      for (const [periodKey, period] of Object.entries(this.$store.state.mainKpiPeriods)) {
        this.mainKpiData[periodKey] = await this.getKpiData(
            period.startDate,
            period.endDate
        )
      }
    },
    getKpiData(start, end) {
      const variables = {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs(start).add(this.$dayjs(start).utcOffset(), 'minute').format('X')),
        dateEnd: Number(this.$dayjs(end).add(this.$dayjs(end).utcOffset(), 'minute').format('X'))
      }
      return this.$apollo.query({
        query: PERFORMANCE_BREAKDOWN,
        variables,
        fetchPolicy: 'network-only',
        loadingKey: 'loading',
        error(error) {
          console.error("We've got an error!", error)
        }
      }).then((response) => {
        const adSpendPerNetSales =
            response.data.dashboardKPI.orders.netSales ?
                response.data.dashboardKPI.totalAdSpend/response.data.dashboardKPI.orders.netSales :
                0
        const conversionRate =
            response.data.dashboardKPI.metrics.sessions ?
                response.data.dashboardKPI.orders.numberOfOrders/response.data.dashboardKPI.metrics.sessions :
                0
        const aov =
            response.data.dashboardKPI.orders.numberOfOrders ?
                response.data.dashboardKPI.orders.totalSales/response.data.dashboardKPI.orders.numberOfOrders :
                0
        return {
          netSales: response.data.dashboardKPI.orders.netSales,
          adSpendPerNetSales: adSpendPerNetSales,
          grossProfit: response.data.dashboardKPI.expenseBreakdown.grossProfit,
          orders: response.data.dashboardKPI.orders.numberOfOrders,
          aov: aov,
          sessions: response.data.dashboardKPI.metrics.sessions,
          conversionRate: conversionRate,
        }
      })
    }
  }
}
</script>

<style scoped>

</style>