import numeral from "numeral";
import 'numeral/locales'
import store from '@/store';
import currencies from "@/config/currencies.json"

export default {
    format(value, format) {
        return numeral(value).format(format)
    },
    getFormatString (formatType, value) {
        const localeData = numeral.localeData()
        switch (formatType) {
            case 'currency':
                if (localeData !== undefined) {
                    return localeData.currency.pattern
                }
                return '0,0.[00]'
            case 'shortCurrency':
                if (localeData !== undefined) {
                    if ( Math.abs(value) /1000000 >= 1) {
                        return localeData.currency.pattern.replace(/[#0,.]+/, '0,0.[0]a')
                    }
                    return localeData.currency.pattern
                }
                return '0,0.[00]'
            case 'percentage':
                return '0.[00]%'
            case 'grayGrowth':
                return '+0%'
            case 'growth':
                return '+0%'
            default:
                return '0,0.[00]'
        }
    },
    install(Vue) {
        Vue.filter("format", (value, formatType) => {
            if (isNaN(value)) {
                return '-'
            }
            const currency = currencies[store.state.selectedProject.currency_id].code || "USD"
            numeral.localeFromCurrency(currency)
            const format = this.getFormatString(formatType, value)
            return this.format(Number(Number(value).toFixed(4)), format);
        })
        Vue.mixin(
            {
                methods: {
                    diffInPercent(selectedPeriod, previousPeriod) {
                        if (selectedPeriod === null || previousPeriod === null ||
                            selectedPeriod === undefined || previousPeriod === undefined ||
                            selectedPeriod === previousPeriod) {
                            return 'na';
                        }

                        if (previousPeriod === 0 && selectedPeriod === 0) {
                            return 'na';
                        }

                        if (previousPeriod === 0) {
                            return -1;
                        }

                        if (previousPeriod === 0) {
                            return 1;
                        }

                        const diff = selectedPeriod / previousPeriod;

                        return diff > 1.0 ? (diff - 1.0) : ((1.0 - diff) * -1.0);
                    }
                }
            }
        )
    },
};