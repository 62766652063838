import en from '@/assets/i18n/en.json'
import hu from '@/assets/i18n/hu.json'
import VueI18n from "vue-i18n"
import Vue from 'vue'

Vue.use(VueI18n);
export default new VueI18n({
    locale: localStorage.getItem('lang') && ['hu', 'en'].includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en',
    messages: {
        hu,
        en
    }
})
