<template>
  <div class="module">
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <expense-kpi :data="kpiData" :colors="colors"/>
      </v-col>
      <v-col
          cols="12"
          md="6"
          align-self="center"
      >
        <pie-chart :chartData="kpiData.selectedPeriod" :colors="colors"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PieChart from "./expenseBreakdown/PieChart";
import ExpenseKpi from "./expenseBreakdown/Kpi";
import EXPENSE_BREAKDOWN from "@/graphql/dashboard/expenseBreakdown.gql";
export default {
  name: "ExpenseBreakdown",
  components: {ExpenseKpi, PieChart},
  data() {
    return{
      colors: {
        adSpendAndMarketing: '#623C9F',
        refunds: '#C0B1D9',
        taxes: '#EB4C4C',
        cogs: '#FF8C21',
        shippingAndHandling: '#ECD05A',
        transactionFees: '#62BF4B',
        overhead: '#228DB6',
        other: '#999999',
      },
      kpiData: {
        selectedPeriod: {},
        previousPeriod: {}
      },
    }
  },
  async created() {
    await this.reCalculateData()
  },
  watch: {
    '$store.state.selectedPeriod'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod: {}
      }
      this.reCalculateData()
    },
    '$store.state.selectedProject'() {
      this.kpiData = {
        selectedPeriod: {},
        previousPeriod: {}
      }
      this.reCalculateData()
    }
  },
  methods: {
    async reCalculateData() {
      this.kpiData.selectedPeriod = await this.getKpiData(
          this.$store.state.selectedPeriod.startDate,
          this.$store.state.selectedPeriod.endDate
      )
      this.kpiData.previousPeriod = await this.getKpiData(
          this.$store.state.previousPeriod.startDate,
          this.$store.state.previousPeriod.endDate
      )
    },
    getKpiData(start, end) {
      const variables = {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs(start).add(this.$dayjs(start).utcOffset(), 'minute').format('X')),
        dateEnd: Number(this.$dayjs(end).add(this.$dayjs(end).utcOffset(), 'minute').format('X'))
      }
      return this.$apollo.query({
        query: EXPENSE_BREAKDOWN,
        variables,
        fetchPolicy: 'network-only',
        loadingKey: 'loading',
        error(error) {
          console.error("We've got an error!", error)
        }
      }).then((response) => {
        return {
          adSpendAndMarketing: response.data.dashboardKPI.expenseBreakdown.adSpendAndMarketing,
          cogs: response.data.dashboardKPI.expenseBreakdown.cogs,
          other: response.data.dashboardKPI.expenseBreakdown.other,
          overhead: response.data.dashboardKPI.expenseBreakdown.overheadExpense,
          refunds: response.data.dashboardKPI.expenseBreakdown.refunds,
          taxes: response.data.dashboardKPI.expenseBreakdown.salesTaxes,
          shippingAndHandling: response.data.dashboardKPI.expenseBreakdown.shippingAndHandling,
          transactionFees: response.data.dashboardKPI.expenseBreakdown.transactionCost,
        }
      })
    }
  }
}
</script>

<style scoped>

</style>