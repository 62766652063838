<template>
  <div>
    <v-row class="d-flex flex-row flex-nowrap">
      <v-col sm="12">
        <kpi-row :data="data" @kpiSelect="changeSelectedKpi"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="4">
        <main-kpi :kpiData="mainKpiData" :selectedKpi="selectedKpi" @mainKpiSelect="mainKpiSelect"/>
      </v-col>
      <v-col sm="12" md="8">
        <performance-chart :selectedKpi="selectedKpi" :selectedMainKpi="selectedMainKpi" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KpiRow from "./kpiWithChart/KpiRow";
import PerformanceChart from "./kpiWithChart/PerformanceChart";
import MainKpi from "./kpiWithChart/MainKpi";
export default {
  name: "KpiWithChart",
  components: {MainKpi, PerformanceChart, KpiRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    mainKpiData: {
      type: Object
    }
  },
  data() {
    return {
      selectedKpi: null,
      selectedMainKpi: null
    }
  },
  methods: {
    changeSelectedKpi(kpi) {
      this.$emit('kpiSelect', kpi)
      this.selectedKpi = kpi;
      this.selectedMainKpi = 'selected';
    },
    mainKpiSelect(interval) {
      this.$emit('mainKpiSelect', interval)
      this.selectedMainKpi = interval;
    }
  }
}
</script>

<style scoped>

</style>