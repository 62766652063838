<template>
  <v-row>
    <v-col cols="6">
      <skeleton-loader
          type="rect"
          :width="200"
          :height="10"
          animation="fade"
      />
      <skeleton-loader
          class="mt-1"
          type="rect"
          :width="200"
          :height="10"
          animation="fade"
      />
      <skeleton-loader
          class="mt-3"
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
      />
    </v-col>
    <v-col cols="6" class="d-flex align-end flex-column">
      <skeleton-loader
          type="rect"
          :width="200"
          :height="10"
          animation="fade"
      />
      <skeleton-loader
          class="mt-1"
          type="rect"
          :width="200"
          :height="10"
          animation="fade"
      />
      <skeleton-loader
          class="mt-3"
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "mainKpiSkeleton"
}
</script>

<style scoped>

</style>