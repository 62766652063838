<template>
  <v-row>
    <v-col cols="6"
           v-for="n in 8"
           :key="n">
      <div class="expense-card">
        <skeleton-loader
            type="rect"
            :width="300"
            :height="15"
            animation="fade"
        />
        <skeleton-loader
            class="mt-3"
            type="rect"
            :width="100"
            :height="25"
            animation="fade"
        />
        <skeleton-loader
            class="mt-1"
            type="rect"
            :width="50"
            :height="10"
            animation="fade"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "KpiSkeleton"
}
</script>

<style scoped>

</style>