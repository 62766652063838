<template>
  <span :class="classList">{{ Number(value) | format('growth') }}</span>
</template>

<script>
export default {
  name: "growth",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    negative: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    classList() {
      let classList = ['font-weight-bold', 'text-nowrap']
      classList.push(this.color)
      return classList.join(' ')
    },
    color() {
      if (isNaN(this.value)) {
        return 'text-gray'
      }if (this.value > 0) {
        if (this.negative){
          return 'text-danger'
        } else {
          return 'text-success'
        }
      } else {
        if (this.negative){
          return 'text-success'
        } else {
          return 'text-danger'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>