<template>
  <v-menu
      open-on-hover
      bottom
      offset-y
      v-if="infoTooltip !== ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-bind="attrs"
           v-on="on">
        <path d="M6.99999 3.5C6.51699 3.5 6.12499 3.892 6.12499 4.375C6.12499 4.858 6.51699 5.25 6.99999 5.25C7.48299 5.25 7.87499 4.85843 7.87499 4.375C7.87499 3.89157 7.48343 3.5 6.99999 3.5ZM6.99999 6.125C6.51699 6.125 6.12499 6.51657 6.12499 7V9.625C6.12499 10.108 6.51699 10.5 6.99999 10.5C7.48299 10.5 7.87499 10.108 7.87499 9.625V7C7.87499 6.517 7.48343 6.125 6.99999 6.125ZM6.99999 0C3.14036 0 -7.62939e-06 3.14037 -7.62939e-06 7C-7.62939e-06 10.8596 3.14036 14 6.99999 14C10.8596 14 14 10.8596 14 7C14 3.14037 10.8596 0 6.99999 0ZM6.99999 12.6875C3.86356 12.6875 1.31249 10.136 1.31249 7C1.31249 3.864 3.86356 1.3125 6.99999 1.3125C10.136 1.3125 12.6875 3.864 12.6875 7C12.6875 10.136 10.136 12.6875 6.99999 12.6875Z" fill="#C4C4C4"/>
      </svg>
    </template>
    <div class="info-tooltip" v-if="infoTooltip!== ''">
      {{ infoTooltip }}
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "infoButton",
  props: {
    infoTooltip: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>