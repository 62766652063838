import allTiles from "@/config/overview/tiles.json"

function getDefaultTiles() {
    let tiles = [];
    for (const [kpi, options] of Object.entries(allTiles)) {
        if (options.default)  {
            tiles.push(kpi);
        }
    }
    return tiles
}

export const state = () => ({
    overviewTiles: getDefaultTiles(),
    goalSettings: {
        type: 'yearly',
        value: 0,
        priceType: 'grossSales'
    }
})

export const mutations = {
    setTiles(state, payload) {
        state.overviewTiles = payload
    },
    setGoalSettings(state, payload) {
        state.goalSettings = payload
    },
}

export const actions = {
}

export const getters = {
    getTiles(state) {
        return state.overviewTiles
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}
