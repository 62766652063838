<template>
  <div id="expenseBreakdownChart">
    <span v-if="Object.keys(chartData).length > 0">
      <apexchart type="donut" ref="donutChart" height="450" :options="chartOptions" :series="series"></apexchart>
    </span>
    <div v-else class="no-data no-data-data-loading d-flex align-items-center justify-content-center text-center pt-10" style="height: 380px;">
      <div class="data-loading">
        <v-img
            class="my-3"
            contain
            :src="require('@/assets/icons/dashboard.svg')"
            width="100"
        />
        <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PieChart",
  props: {
    chartData: {
      type: Object,
      default() {
        return {}
      }
    },
    colors: {
      type: Object
    }
  },
  watch: {
    chartData() {
      if (this.$refs.donutChart) {
        this.$refs.donutChart.refresh()
      }
    }
  },
  computed: {
    series() {
      let values = [];
      Object.values(this.chartData).forEach((value) => {
        if (value > 0) {
          values.push(value);
        }
      })
      return values;
    },
    total() {
      let total = 0;
      Object.values(this.chartData).forEach((value) => {
        if (value > 0) {
          total += Number(value);
        }
      })
      return total;
    },
    chartColors() {
      let colors = [];
      Object.entries(this.chartData).forEach(([kpi, value]) => {
        if (value > 0) {
          colors.push(this.colors[kpi]);
        }
      })
      return colors;
    },
    labels() {
      let labels = [];
      Object.entries(this.chartData).forEach(([kpi, value]) => {
        if (value > 0) {
          labels.push(this.$t('kpiNames.' + kpi));
        }
      })
      return labels;
    },
    chartOptions() {
      return {
        labels: this.labels,
        chart: {
          type: 'donut',
          height: '450px'
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: '100%',
                height: 'auto'
              },
            }
          }
        ],
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => {
              return this.$options.filters.format(value, 'shortCurrency');
            }
          },
        },
        colors: this.chartColors,
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '13px',
                  color: '#999999',
                  formatter: function (val) {
                    return val.toUpperCase()
                  }
                },
                value: {
                  show: true,
                  color: '#666',
                  fontSize: '36px',
                  fontWeight: 500,
                  formatter: (val) => {
                    return this.$options.filters.format(val, 'shortCurrency')
                  }
                },
                total: {
                  show: true,
                  label: this.$t('chart.totalExpenses'),
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#999999',
                  formatter: () => {
                    return this.$options.filters.format(this.total, 'shortCurrency')
                  }
                }
              }
            }
          }
        },
        legend: {
          show: false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>