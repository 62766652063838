<template>
    <div>
      <!-- <warning-div translateTextKey="dashboard.facebookAlertText"></warning-div> -->
      <in-app-videos youtubeVideoLink="https://www.youtube.com/embed/XaIFZzVUQso?rel=0&showinfo=0">
        <template v-slot:content>
          <div class="text-conv-gray2">{{ $t('dashboard.titleText') }}</div>
          <div class="text-conv-gray2">
            <ul>
              <li>{{ $t('dashboard.firstLine') }}</li>
              <li>{{ $t('dashboard.secondLine') }}</li>
              <li>{{ $t('dashboard.thirdLine') }}</li>
            </ul>
          </div>
        </template>
        <template v-slot:closedContent>
          {{ $t('dashboard.closedContent')}}
        </template>
      </in-app-videos>
      <performance-breakdown />
      <section-header
          :title="$t('sectionHeader.overview.title')"
          :number="1"
          :info-tooltip="$t('sectionHeader.overview.tooltip')"
      >
        <template v-slot:button>
          <customize-overview />
        </template>
      </section-header>
      <overview/>
      <section-header
          :title="$t('sectionHeader.profitAndLoss.title')"
          :number="2"
          :info-tooltip="$t('sectionHeader.profitAndLoss.tooltip')"
      >
        <template v-if="this.$store.state.selectedProject.projectRole !== 'FREE'" v-slot:button>
          <v-col cols="2">
            <v-select
                v-model="profitAndLossIntervalType"
                :items="profitAndLossIntervalTypes"
                dense
                outlined
                hide-details
            ></v-select>
          </v-col>
        </template>
      </section-header>
      <profit-and-loss-not-available v-if="this.$store.state.selectedProject.projectRole === 'FREE'" />
      <profit-and-loss v-else :intervalType="profitAndLossIntervalType"/>
      <section-header
          :title="$t('sectionHeader.expenseBreakdown.title')"
          :number="3"
          :info-tooltip="$t('sectionHeader.expenseBreakdown.tooltip')"
      >
        <template v-slot:button>
          <v-col>
            <v-btn
                class="section-button text-capitalize"
                color="secondary"
                elevation="0"
                small
                :to="{name: 'ProfitReport', params: {projectId: $route.params.projectId}}"
            >
              {{ $t("sectionHeader.showMore") }}
            </v-btn>
          </v-col>
        </template>
      </section-header>
      <expense-breakdown />
      <section-header
          :title="$t('sectionHeader.adSpendBreakdown.title')"
          :number="4"
          :info-tooltip="$t('sectionHeader.adSpendBreakdown.tooltip')"
      />
      <ad-spend-breakdown />
      <section-header
          :title="$t('goal.title.' + $store.state.dashboard.goalSettings.type)"
          :number="5"
          :info-tooltip="$t('sectionHeader.yearlyProgress.tooltip')"
      >
        <template v-slot:button>
          <goal-dialog />
        </template>
      </section-header>
      <yearly-goal-progress />
    </div>
</template>

<script>
  import ExpenseBreakdown from "@/components/dashboard/ExpenseBreakdown";
  import YearlyGoalProgress from "@/components/dashboard/YearlyGoalProgress";
  import SectionHeader from "@/components/common/SectionHeader";
  import PerformanceBreakdown from "@/components/dashboard/PerformanceBreakdown";
  import AdSpendBreakdown from "@/components/dashboard/AdSpendBreakdown";
  import GoalDialog from "@/components/dashboard/yearlyProgress/GoalDialog";
  import Overview from "@/components/dashboard/Overview";
  import ProfitAndLoss from "@/components/dashboard/profitAndLoss/ProfitAndLoss";
  import CustomizeOverview from "@/components/dashboard/overview/CustomizeOverview";
  import ProfitAndLossNotAvailable from "@/components/dashboard/profitAndLoss/ProfitAndLossNotAvailable";
  import InAppVideos from "../components/dashboard/InAppVideos.vue";  
  export default {
    name: 'Home',
    components: {
      ProfitAndLossNotAvailable,
      CustomizeOverview,
      ProfitAndLoss,
      Overview,
      GoalDialog,
      AdSpendBreakdown,
      PerformanceBreakdown,
      SectionHeader,
      YearlyGoalProgress,
      ExpenseBreakdown,
      InAppVideos
    },
    data() {
      return {        
        profitAndLossIntervalType: 'monthly',
        profitAndLossIntervalTypes: [
          {
            value: 'monthly',
            text: this.$t('profitAndLoss.intervalType.monthly')
          },
          {
            value: 'daily',
            text: this.$t('profitAndLoss.intervalType.daily')
          }
        ]
      }
    }
  }
</script>
