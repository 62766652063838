<template>
  <v-slide-group
      v-if="Object.keys(data.selectedPeriod).length > 0"
      mandatory
      show-arrows
      class="kpi-holder"
      v-model="selectedKpi"
  >
    <v-slide-item
        v-for="(value, kpi) in data.selectedPeriod"
        :key="kpi"
        v-slot="{ active, toggle }"
        style="width: 100%"
    >
      <v-card
          :class="active ? 'active' : ''"
          class="kpi-item"
          @click="toggle"
          :ripple="false"
      >
        <v-tooltip
            bottom
            color="#fff"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="inner"
                 v-bind="attrs"
                 v-on="on">
              <v-card-title>
                {{ $t('kpiNames.' + kpi) }}
              </v-card-title>
              <v-row class="kpi-content">
                  <div class="value">{{ value | format(ValueFormats[kpi]['kpi'] || 'number') }}</div>
                  <growth class="growth" :value="diffInPercent(value, data.previousPeriod[kpi])" :negative="ValueFormats[kpi].negativeGrowth || false"/>
              </v-row>
            </div>
          </template>
          <div class="kpiRow-tooltip">
            {{ $t('kpiTooltip.' + kpi) }}
          </div>
        </v-tooltip>
      </v-card>
    </v-slide-item>
  </v-slide-group>
  <v-slide-group
      v-else
      mandatory
      show-arrows
      class="kpi-holder"
  >
    <v-slide-item
        v-for="n in 7"
        :key="n"
        v-slot="{ active, toggle }"
        style="width: 100%"
    >
      <v-card
          :class="active ? 'active' : ''"
          class="kpi-item"
          @click="toggle"
          :ripple="false"
      >
        <kpi-skeleton />
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import Growth from "@/components/common/growth";
import ValueFormats from "@/config/ValueFormats.json";
import KpiSkeleton from "./KpiSkeleton";

export default {
  name: "KpiRow",
  components: {KpiSkeleton, Growth},
  props: {
    data: {
      type: Object
    },
  },
  data() {
    return {
      selectedKpi: null,
      ValueFormats
    }
  },
  watch: {
    selectedKpi() {
      this.$emit('kpiSelect', Object.keys(this.data.selectedPeriod)[this.selectedKpi])
    }
  }
}
</script>

<style scoped>

</style>