<template >    
    <div class="module" v-if="locale === 'en'">
      <v-row>
            <div v-if="showVideos" id="howThisWorkVideoOpened" style="font-size: 1.3rem;">
                <div class="ibox ibox-md d-flex align-items-center justify-content-center" style="width:fit-content;border-radius: 28px;border: 1px solid #ebebeb;padding: 5px;">
                    <div v-if="link" class="d-flex align-items-center pl-3 video-container" >
                        <iframe
                            :src="link"
                            title="In app videos" 
                            frameborder="0"
                            width="560" height="315"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                    <div class="d-flex flex-column p-1 pl-3 pr-3" style="min-height: 300px;">
                        <div style="vertical-align: top;">
                            <slot name="content"></slot>
                        </div>
                        <div @click="showVideos = !showVideos" class="text-conv-gray3 fs-13 mt-auto  justify-content-end hide-button " style="cursor: pointer;text-align: right;width: 100%;" id="howThisWorkVideoHiddenButton">
                            <span style="margin-right:3px;">{{ $t('inAppVideos.hideButtonText') }}</span><v-icon x-small>mdi-eye-off-outline</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else id="howThisWorkVideoClosedContainer" >
                <div @click="showVideos = !showVideos" id="howThisWorkVideoClosed" class="ibox ibox-md d-flex align-items-center justify-content-center how-this-work-video-closed-div" style="width:fit-content;border-radius: 15px;border: 1px solid #ebebeb;padding: 5px;">
                    <span class="ci mr-3">
                        <svg width="30" height="30" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M 509.3 139.9 C 506.7 133.8 502.4 128.6 497 124.9 C 494.3 123.1 491.3 121.6 488 120.6 C 484.8 119.6 481.4 119.1 477.9 119.1 C 473.1 119.1 468.4 120.1 464 122 C 463.1 122.4 461.9 123.1 461.1 123.6 L 372.9 185.7 C 363.8 192.1 358.4 202.5 358.4 213.6 L 358.4 298.3 C 358.4 309.4 363.8 319.8 372.9 326.2 L 372.9 326.3 L 461.1 388.4 C 461.9 389 463.1 389.6 464 390 C 468.5 392 473.2 393 477.9 392.9 C 484.5 392.9 490.8 391 496.3 387.5 C 501.8 384 506.3 378.9 509.1 372.6 C 511 368.2 512 363.5 512 358.7 L 512 153.2 C 512 148.5 511 144 509.3 139.9 Z" style="fill: rgb(98, 60, 159);"/>
                                <path d="M 309.3 114.8 C 303.6 110.9 297.2 107.8 290.5 105.7 C 283.7 103.6 276.5 102.4 269.1 102.4 L 72.5 102.4 C 62.6 102.4 53 104.5 44.3 108.2 C 31.3 113.7 20.3 122.9 12.5 134.4 C 8.6 140.2 5.5 146.6 3.4 153.4 C 1.2 160.2 0 167.4 0 174.9 L 0 337.1 C 0 347 2.1 356.6 5.8 365.3 C 11.3 378.3 20.5 389.3 32 397.1 C 37.8 401 44.2 404.1 51 406.2 C 57.8 408.3 65.1 409.5 72.5 409.5 L 268.7 409.5 L 268.7 409.6 C 278.6 409.6 288.2 407.5 296.9 403.8 C 309.9 398.3 320.9 389.1 328.7 377.6 C 332.6 371.8 335.7 365.4 337.8 358.6 C 339.9 351.8 341.1 344.5 341.1 337.1 L 341.1 174.4 C 341.1 164.5 339 155 335.4 146.4 C 329.9 133.5 320.8 122.5 309.3 114.8 Z" style="fill: rgb(98, 60, 159);"/>
                            </g>
                        </svg>
                    </span>
                    <a class="moduleAnchor font-weight-500 text-primary" >
                        <slot name="closedContent"></slot>
                    </a>
                    <span class="play-button">
                        <svg width="35" height="35" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M 500 10 C 229.8 10 10 229.8 10 500 C 10 770.2 229.8 990 500 990 C 770.2 990 990 770.2 990 500 C 990 229.8 770.2 10 500 10 Z" style="fill: rgb(230, 214, 255);"/>
                                <path d="M417.5,283c-11.6-11.8-39.6-19.6-39.6,3v427.9c0,23,28,14.6,39.6,3l268.2-195.7c11.6-11.8,11.6-30.7,0-42.5L417.5,283z" style="fill: rgb(98, 60, 159);"/>
                            </g>
                        </svg>                          
                    </span>
                    <button type="button" class="video-button text-uppercase" style="position:relative;left:-13px;width:max-content;border-radius: 20px;line-height:2px;">{{ $t('inAppVideos.watchVideo') }}</button>
                </div>
            </div>
      </v-row>
    </div>
  </template>
  
  <script>
  import {mapGetters} from "vuex"

  export default {
    name: "InAppVideos",
    props: {
        youtubeVideoLink: String
    },
    data() {
      return{
        showVideos: true,
        link: this.youtubeVideoLink,
        locale: 'en'
      }
    },
    async mounted() {
        this.showVideos = this.selectedProject.how_this_work_videos;
        this.locale = localStorage.getItem('lang') ?? 'en';
    },
    computed: {
        ...mapGetters({
            selectedProject: 'getSelectedProject'
        })
    },
    watch: {
    },
    methods: {
    }
  }
  </script>
  
  <style scoped>
    .video-button {
        cursor: pointer;
        position: relative;
        left: -13px;
        width: max-content;
        border-radius: 20px;
        line-height: 2px;
        cursor: pointer;
        color: #fff;
        background-color: #623c9f;
        border-color: #623c9f;
        box-shadow: none;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        /*vertical-align: middle;*/
        user-select: none;
        border: 1px solid transparent;
        padding: .75rem 1.6rem;
        font-size: 1rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        box-sizing: border-box;
        margin: 0;
        font-family: inherit;
    }

    .play-button {
        z-index: 99999;
        padding-left: 1.5rem !important;
        outline: 0 !important;
        box-sizing: border-box;
        margin-top: 8px;
    }

    .align-items-center {
        align-items: center !important;
    }

    .d-flex {
       display: flex !important;
    }

    .how-this-work-video-closed-div{
        width: fit-content;
        border-radius: 15px;
        border: 1px solid #ebebeb;
        padding: 5px;
    }

    .text-conv-gray3 {
        color: #999;
    }

    .text-conv-gray2 {
        color: #666;
    }
  </style>