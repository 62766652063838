<template>
  <div class="module">
    <v-row v-if="!loading" style="height: 50px">
      <v-col sm="1" class="yearly-progress" :style="'color: ' + progressColor">
        <div class="yearly-goal-value">{{ yearlyProgress[priceType] | format('shortCurrency') }}</div>
        <div class="yearly-goal-type">({{ $t('goal.options.' + priceType) }})</div>
      </v-col>
      <v-col class="d-flex align-start" :style="'flex-direction: column; padding: 0;' + (goalSettings.type === 'daily' ? 'padding-top:25px;' : '')" ref="progressHolder">
        <div v-show="goalSettings.type !== 'daily' && !loading" ref="target" class="yearly-target-container"
             :style="'position:relative; left: ' + marginLeft + 'px;'">
          <span class="yearly-target-title">{{ $t('goal.target') }}</span>
        </div>
        <svg width="100%" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="12" width="100%" height="8" rx="4" fill="#E4E6ED"/>
          <rect y="12" :width="(yearlyPercentage * 100) +'%'" height="8" rx="4" :fill="progressColor"/>
          <rect v-show="goalSettings.type !== 'daily' && !loading" :x="pipeXDimension" y="1.5" width="29"
                height="9" rx="4.5"
                :transform="'rotate(90 ' + pipeXDimension + ' 1.5)'" :fill="pipeColor" stroke="white"
                stroke-width="3"/>
        </svg>
      </v-col>
      <v-col sm="1" class="yearly-goal-container">
        <div class="yearly-goal-value">{{ goalValue | format('shortCurrency') }}</div>
        <div class="yearly-goal-date">({{ goalText }})</div>
      </v-col>
    </v-row>
    <v-row v-else style="height: 50px">
      <v-col sm="1" class="yearly-progress" :style="'color: ' + progressColor">
        <div class="yearly-goal-value">
          <skeleton-loader
              type="rect"
              :width="50"
              :height="15"
              animation="fade"
          />
        </div>
        <div class="yearly-goal-type">
          <skeleton-loader
              class="mt-3"
              type="rect"
              :width="100"
              :height="15"
              animation="fade"
          />
        </div>
      </v-col>
      <v-col class="d-flex align-start" style="flex-direction: column; padding-top: 30px" ref="progressHolder">
        <svg width="100%" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="12" width="100%" height="8" rx="4" fill="#E4E6ED"/>
          <rect v-show="!loading" :x="width*monthlyPercentage" y="1.5" width="29" height="9" rx="4.5"
                :transform="'rotate(90 ' + (width*monthlyPercentage) + ' 1.5)'" :fill="pipeColor" stroke="white"
                stroke-width="3"/>
        </svg>
        <div ref="target" class="yearly-target-container" style="position: fixed; left: -10px; top: -10px">
          {{ $t('goal.target') }}
        </div>
      </v-col>
      <v-col sm="1" class="yearly-goal-container">
        <div class="yearly-goal-value">
          <skeleton-loader
              type="rect"
              :width="50"
              :height="15"
              animation="fade"
          />
        </div>
        <div class="yearly-goal-date">
          <skeleton-loader
              class="mt-3"
              type="rect"
              :width="100"
              :height="15"
              animation="fade"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GOAL from "@/graphql/dashboard/goal.gql";
import GET_SETTINGS from "@/graphql/settings/getSettings.gql"

export default {
  name: "YearlyGoalProgress",
  apollo: {
    goalSettings: {
      query: GET_SETTINGS,
      manual: true,
      variables() {
        return this.variables
      },
      result({data}) {
        if (Object.keys(data.settings.settings).includes('project') &&
            Object.keys(data.settings.settings.project).includes('goal')) {
          this.goalSettings = JSON.parse(data.settings.settings.project.goal)
        }
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
    goalValues: {
      query: GOAL,
      manual: true,
      variables() {
        return this.goalDataVariables
      },
      result({data}) {
        if (Object.keys(data).includes('goal')) {
          this.yearlyProgress = data.goal
        }
        this.onResize();
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
  },
  data() {
    return {
      width: null,
      targetWidth: null,
      yearlyProgress: {
        grossSales: 0,
        netSales: 0,
        grossProfit: 0,
        netProfit: 0
      },
      thisYear: this.$dayjs().format('YYYY'),
      targetDate: this.$dayjs().format('MMM YYYY'),
      loading: 0
    }
  },
  async created() {
    window.addEventListener("resize", this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 100)
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    goalText() {
      switch (this.goalSettings.type) {
        case 'quarterly':
          return this.$t('goal.goalText.quarterly', {
            'year': this.$dayjs().format('YYYY'),
            'quarter': this.$dayjs().quarter()
          })
        case 'monthly':
          return this.$t('goal.goalText.monthly', {
            'month': this.$dayjs().format('YYYY/MM')
          })
        case 'weekly':
          return this.$t('goal.goalText.weekly', {
            'year': this.$dayjs().format('YYYY'),
            'week': this.$dayjs().format('wo')
          })
        case 'daily':
          return this.$t('goal.goalText.daily', {
            'date': this.$dayjs().format('YYYY-MM-DD')
          })
        default:
          return this.$t('goal.goalText.yearly', {'year': this.$dayjs().format('YYYY')})
      }
    },
    goalSettings: {
      get() {
        return this.$store.state.dashboard.goalSettings
      },
      set(value) {
        this.$store.commit('setGoalSettings', value)
      }
    },
    variables() {
      return {
        projectId: Number(this.$store.state.selectedProject.id),
        settingKey: 'goal'
      }
    },
    goalDataVariables() {
      let dateStart = Number(this.$dayjs().startOf('year').add(this.$dayjs().utcOffset(), 'minute').format('X'))
      let dateEnd = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
      switch (this.goalSettings.type) {
        case 'quarterly':
          dateStart = Number(this.$dayjs().startOf('quarter').add(this.$dayjs().utcOffset(), 'minute').format('X'))
          dateEnd = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
          break;
        case 'monthly':
          dateStart = Number(this.$dayjs().startOf('month').add(this.$dayjs().utcOffset(), 'minute').format('X'))
          dateEnd = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
          break;
        case 'weekly':
          dateStart = Number(this.$dayjs().startOf('week').add(this.$dayjs().utcOffset(), 'minute').format('X'))
          dateEnd = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
          break;
        case 'daily':
          dateStart = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).startOf('day').add(this.$dayjs().utcOffset(), 'minute').format('X'))
          dateEnd = Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs().utcOffset(), 'minute').format('X'))
          break;

      }
      return {
        projectId: Number(this.$route.params.projectId),
        dateStart,
        dateEnd,
        intervalType: this.goalSettings.type
      }
    },
    yearlyPercentage() {
      if (this.yearlyProgress[this.priceType] > 0 && this.goalValue > 0) {
        let percentageValue = this.yearlyProgress[this.priceType] / this.goalValue;
        percentageValue = percentageValue.toFixed(2);
        return percentageValue > 1 ? 1 : percentageValue;
      }
      return 0
    },
    priceType() {
      return this.$store.state.dashboard.goalSettings.priceType
    },
    goalValue() {
      return this.$store.state.dashboard.goalSettings.value
    },
    monthlyPercentage() {
      switch (this.goalSettings.type) {
        case 'quarterly':
          return Number(this.$dayjs().diff(this.$dayjs().startOf('quarter'), "day")) / Number(this.$dayjs().endOf('quarter').diff(this.$dayjs().startOf('quarter'), "day"))
        case 'monthly':
          return Number(this.$dayjs().format('D')) / this.$dayjs().daysInMonth()
        case 'weekly':
          return (Number(this.$dayjs().subtract(1, 'day').isoWeekday())) / 7
        case 'daily':
          return 0
        default:
          return Number(this.$dayjs().format('M')) / 12
      }
    },
    progressColor() {
      if (this.goalSettings.type === "daily") {
        return this.yearlyPercentage >= 1 ? '#33BF7B' : '#DC3241';
      }
      return this.yearlyPercentage > this.monthlyPercentage ? '#33BF7B' : '#DC3241';
    },
    pipeColor() {
      return this.yearlyPercentage > this.monthlyPercentage ? '#33BF7B' : '#E4E6ED';
    },
    marginLeft() {
      return this.width * this.monthlyPercentage - this.targetWidth / 2;
    },
    pipeXDimension() {
      return this.monthlyPercentage > 0 ? this.width * this.monthlyPercentage : 10;
    }
  },
  methods: {
    onResize() {
      this.width = Math.round(this.$refs.progressHolder.clientWidth);
      this.targetWidth = Math.round(this.$refs.target.clientWidth);
    }
  },
}
</script>

<style scoped>

</style>