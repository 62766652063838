<template>
  <v-dialog
      v-model="dialog"
      width="800"
      style="align-items: flex-start!important;"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" style="justify-content: space-between!important;">
        {{ $t('projectOwnerError.title') }}
        <span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text class="pt-3">
        {{ $t('projectOwnerError.description') }}
        <v-divider class="mt-3 mb-3"></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            color="primary"
            @click="dialog = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SettingDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>