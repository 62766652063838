<template>
  <span>
  <skeleton-loader
      type="rect"
      :width="200"
      :height="20"
      animation="fade"
  />
  <skeleton-loader
      class="mt-4"
      type="rect"
      :width="100"
      :height="30"
      animation="fade"
  />
  </span>
</template>

<script>
export default {
  name: "kpiSkeleton"
}
</script>

<style scoped>

</style>