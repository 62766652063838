import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {createApolloClient, restartWebsockets} from 'vue-cli-plugin-apollo/graphql-client'
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import Cookies from "js-cookie";

// Install the vue plugin
Vue.use(VueApollo)

const AUTH_TOKEN = Cookies.get('ConversificAuthToken') || ''

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || process.env.VUE_APP_BACKEND_URL + '/graphql'
// Files URL root
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

const defaultOptions = {
  wsEndpoint: null,
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([
    new HttpLink({
      uri: httpEndpoint,
      headers: {authorization: `Bearer ${AUTH_TOKEN}`}
    })
  ]),
}

export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
  // ...options
})
apolloClient.wsClient = wsClient

// Call this in the Vue app file
export function createProvider (options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
