<template>
  <div class="module" style="padding-top: 0!important;">
    <v-simple-table id="profitAndLoss" class="table table-first-column-highlight" style="border-spacing:0;">
      <template v-slot:default>
        <thead>
          <tr>
            <th rowspan="1" colspan="1"></th>
            <th
                v-for="date in dateColumns"
                :key="date">
              {{ date }}
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
        <tr
            v-for="i in 10"
            :key="i"
        >
          <td
              v-for="n in dateColumns.length"
              :key="n">
            <skeleton-loader
                class="align-center"
                type="rect"
                :width="120"
                :height="30"
                animation="fade"
            />
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="filteredDataLength > 0">
        <template v-for="(definition, kpi) in definitions">
        <tr
            v-if="!definition.parent || openedRowIds.includes(definition.parent)"
            :key="kpi"
            :id="kpi"
            :class="openedRowIds.includes(definition.parent) || openedRowIds.includes(kpi) ? 'open' : ''"
        >
          <td
              v-if="Object.keys(filteredData).includes(kpi)"
              :class="definition.styleKey"
              @click="hasSubcategory(kpi) ? toggleSubRows(kpi) : null"
          >
            <span
                v-if="hasSubcategory(kpi)"
                class="expand-button p-2 mr-2"
            >
              <font-awesome-icon v-if="openedRowIds.includes(kpi)" :icon="['fa', 'angle-up']"/>
              <font-awesome-icon v-else :icon="['fa', 'angle-down']"/>
            </span>
            {{ getKpiName(kpi) }}
          </td>
          <template v-for="(value, date) in filteredData[kpi]">
            <td
                v-if="Object.keys(filteredData).includes(kpi)"
                :key="date"
            >
              {{ (definition.negative ? -1*value : value) | format(definition.format || 'number') }}
            </td>
          </template>
        </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import PROFIT_AND_LOSS from "@/graphql/dashboard/profitAndLoss.gql";
import definitions from "@/config/profitAndLoss/definitions.json";
import customExpenses from "@/config/profitAndLoss/customExpenses.json";

export default {
  name: "ProfitAndLoss",
  props: {
    intervalType: {
      type: String,
      default: "monthly"
    }
  },
  data() {
    return {
      definitions: definitions,
      filteredData: {},
      openedRowIds: [],
      loading: 0,
    }
  },
  apollo: {
    profitAndLoss: {
      query: PROFIT_AND_LOSS,
      manual: true,
      variables () {
        return this.apolloVariables
      },
      result ({data}) {
        this.definitions = definitions;
        data = data.profitAndLoss.data;
        let filteredData = {};
        for (const [kpi, values] of Object.entries(data)) {
          let sum = 0;
          Object.values(values).forEach((value) => {
            sum += Number(value)
          })
          if (sum > 0 || !customExpenses.includes(kpi)) {
            filteredData[kpi] = {};
            if (kpi.substring(0, 19) === 'transactionFees!@-#') {
              const fieldsArray = Object.entries(this.definitions)
              const index = fieldsArray.findIndex(([key]) => key === 'transactionFees')
              this.definitions = Object.fromEntries([
                ...fieldsArray.slice(0, index),
                fieldsArray[index],
                ...[[kpi, {format: 'shortCurrency', negative: true, styleKey: 'level3', parent: 'transactionFees'}]],
                ...fieldsArray.slice(index+1)])
            }
            filteredData[kpi] = Object.keys(values).sort().reverse().reduce(
                (obj, key) => {
                  obj[key] = values[key];
                  return obj;
                },
                {}
            );
          }
        }
        this.filteredData = filteredData;
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
  },
  computed: {
    start() {
      if (this.intervalType === 'monthly') {
        return this.$dayjs(this.$store.state.selectedPeriod.startDate).subtract(1, 'year').startOf('month').toDate()
      }
      return this.$dayjs(this.$store.state.selectedPeriod.startDate).subtract(1, 'month').toDate()
    },
    end() {
      return this.$dayjs(this.$store.state.selectedPeriod.endDate)
    },
    dateColumns() {
      const start = this.$dayjs(this.start).format('X')
      const end = this.$dayjs(this.end).format('X')
      let multiply = 'day';
      let format = 'lll';
      if (this.intervalType === 'monthly') {
        multiply = 'month';
        format = 'll';
      }
      let dates = [];
      for(let i = start; i < end; i = this.$dayjs.unix(i).add(1, multiply).format('X')){
        const date = this.$dayjs.unix(i).format(format);
        dates.push(date);
      }

      return dates.reverse()
    },
    filteredDataLength() {
      return Object.keys(this.filteredData).length
    },
    apolloVariables() {
      return {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs.utc(this.start).startOf("day").format("X")),
        dateEnd: Number(this.$dayjs.utc(this.end).endOf("day").format("X")),
        intervalType: this.intervalType
      }
    }
  },
  created() {
    this.$apollo.queries.profitAndLoss.start()
  },
  methods: {
    toggleSubRows(kpi) {
      if (this.openedRowIds.includes(kpi)) {
        this.openedRowIds.splice(this.openedRowIds.indexOf(kpi), 1)
        this.openedRowIds.forEach((value) => {
          if (this.definitions[value].parent === kpi) {
            this.openedRowIds.splice(this.openedRowIds.indexOf(value), 1)
          }
        })
      } else {
        this.openedRowIds.push(kpi)
      }
    },
    hasSubcategory(kpi) {
      for (const [subKpi, value] of Object.entries(this.definitions)) {
        if (value.parent === kpi && Object.keys(this.filteredData).includes(subKpi)) {
          return true
        }
      }
      return false
    },
    getKpiName(kpi) {
      if (kpi.substring(0, 19) !== 'transactionFees!@-#') {
        return this.$t('profitAndLoss.' + kpi);
      }
      const transactionFee = kpi.substring(19);
      const translatedTransactionFee = this.$t('profitAndLoss.transactionFee.' + transactionFee);
      return translatedTransactionFee === "profitAndLoss.transactionFee." + transactionFee ?
          transactionFee:
          translatedTransactionFee
    }
  }
}
</script>

<style scoped>
</style>