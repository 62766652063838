<template>
  <v-tooltip
      bottom
      color="#fff"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card
          class="mx-auto overview-card"
          :ripple="false"
          v-bind="attrs"
          v-on="on"
      >
        <v-card-text>
          <div class="overview-title-holder">
            <div class="overview-title">
              {{ $t('kpiNames.' + kpi) }}
            </div>
<!--            <div class="overview-info">-->
<!--              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                    d="M6.99999 3.5C6.51699 3.5 6.12499 3.892 6.12499 4.375C6.12499 4.858 6.51699 5.25 6.99999 5.25C7.48299 5.25 7.87499 4.85843 7.87499 4.375C7.87499 3.89157 7.48343 3.5 6.99999 3.5ZM6.99999 6.125C6.51699 6.125 6.12499 6.51657 6.12499 7V9.625C6.12499 10.108 6.51699 10.5 6.99999 10.5C7.48299 10.5 7.87499 10.108 7.87499 9.625V7C7.87499 6.517 7.48343 6.125 6.99999 6.125ZM6.99999 0C3.14036 0 -7.62939e-06 3.14037 -7.62939e-06 7C-7.62939e-06 10.8596 3.14036 14 6.99999 14C10.8596 14 14 10.8596 14 7C14 3.14037 10.8596 0 6.99999 0ZM6.99999 12.6875C3.86356 12.6875 1.31249 10.136 1.31249 7C1.31249 3.864 3.86356 1.3125 6.99999 1.3125C10.136 1.3125 12.6875 3.864 12.6875 7C12.6875 10.136 10.136 12.6875 6.99999 12.6875Z"-->
<!--                    fill="#C4C4C4"/>-->
<!--              </svg>-->
<!--            </div>-->
          </div>
          <div
              v-if="!loading"
              class="overview-value-holder"
          >
            <div class="overview-value">
              {{ selectedPeriod | format(ValueFormats[kpi]['mainKpi'] || 'number') }}
            </div>
            <growth class="growth" :value="diffInPercent(selectedPeriod, previousPeriod)"
                    :negative="ValueFormats[kpi].negativeGrowth || false"/>
          </div>
          <skeleton-loader
              v-else
              class="mt-3"
              type="rect"
              :width="150"
              :height="30"
              animation="fade"
          />
        </v-card-text>
      </v-card>
    </template>
    <div class="tile-tooltip">
      {{ $t('overview.modal.overview.whatIsThis.' + kpi) }}
    </div>
  </v-tooltip>
</template>

<script>
import Growth from "@/components/common/growth";
import TOTAL_SALES from "@/graphql/dashboard/overview/totalSales.gql"
import DISCOUNTS from "@/graphql/dashboard/overview/discounts.gql"
import REFUNDS from "@/graphql/dashboard/overview/refunds.gql"
import SALES_TAXES from "@/graphql/dashboard/overview/salesTaxes.gql"
import SHIPPING_CHARGED from "@/graphql/dashboard/overview/shippingCharged.gql"
import ITEM_SOLD from "@/graphql/dashboard/overview/itemSold.gql"
import LTV from "@/graphql/dashboard/overview/ltv.gql"
import CAC from "@/graphql/dashboard/overview/cac.gql"
import NEW_CUSTOMERS from "@/graphql/dashboard/overview/newCustomers.gql"
import RETURNING_CUSTOMERS from "@/graphql/dashboard/overview/returningCustomers.gql"
import NET_PROFIT from "@/graphql/dashboard/overview/netProfit.gql"
import GROSS_PROFIT from "@/graphql/dashboard/overview/grossProfit.gql"
import NET_MARGIN from "@/graphql/dashboard/overview/netMargin.gql"
import GROSS_MARGIN from "@/graphql/dashboard/overview/grossMargin.gql"
import AVERAGE_ORDER_PROFIT from "@/graphql/dashboard/overview/averageOrderProfit.gql"
import COGS from "@/graphql/dashboard/overview/cogs.gql"
import TRANSACTION_FEES from "@/graphql/dashboard/overview/transactionFees.gql"
import SHIPPING_COSTS from "@/graphql/dashboard/overview/shippingCosts.gql"
import GA_ADS_SPEND from "@/graphql/dashboard/overview/gaAdsSpend.gql"
import FB_ADS_SPEND from "@/graphql/dashboard/overview/fbAdsSpend.gql"
import LTV_PER_CAC from "@/graphql/dashboard/overview/ltvPerCac.gql"
import REPEAT_PURCHASE_RATE from "@/graphql/dashboard/overview/repeatPurchaseRate.gql"
import ValueFormats from "@/config/ValueFormats.json"

export default {
  name: "Tile",
  components: {Growth},
  props: {
    kpi: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedPeriod: 0,
      previousPeriod: 0,
      loading: 0,
      ValueFormats: ValueFormats
    }
  },
  apollo: {
    selectedPeriodData: {
      query() {
        return this.query
      },
      manual: true,
      variables() {
        return this.selectedPeriodVariables
      },
      result({data}) {
        this.selectedPeriod = this.fetchData(data);
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
    previousPeriodData: {
      query() {
        return this.query
      },
      manual: true,
      variables() {
        return this.previousPeriodVariables
      },
      result({data}) {
        this.previousPeriod = this.fetchData(data);
      },
      error(error) {
        console.error("We've got an error!", error)
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
    },
  },
  computed: {
    query() {
      switch (this.kpi) {
        case 'totalSales':
          return TOTAL_SALES
        case 'discounts':
          return DISCOUNTS
        case 'refunds':
          return REFUNDS
        case 'salesTaxes':
          return SALES_TAXES
        case 'shippingCharged':
          return SHIPPING_CHARGED
        case 'itemSold':
          return ITEM_SOLD
        case 'ltv':
          return LTV
        case 'cac':
          return CAC
        case 'newCustomers':
          return NEW_CUSTOMERS
        case 'returningCustomers':
          return RETURNING_CUSTOMERS
        case 'netProfit':
          return NET_PROFIT
        case 'grossProfit':
          return GROSS_PROFIT
        case 'netMargin':
          return NET_MARGIN
        case 'grossMargin':
          return GROSS_MARGIN
        case 'averageOrderProfit':
          return AVERAGE_ORDER_PROFIT
        case 'cogs':
          return COGS
        case 'transactionFees':
          return TRANSACTION_FEES
        case 'shippingCosts':
          return SHIPPING_COSTS
        case 'gaAdsSpend':
          return GA_ADS_SPEND
        case 'fbAdsSpend':
          return FB_ADS_SPEND
        case 'ltvPerCac':
          return LTV_PER_CAC
        case 'repeatPurchaseRate':
          return REPEAT_PURCHASE_RATE
        default:
          console.error("Kpi not found")
          return ''
      }
    },
    selectedPeriodVariables() {
      return {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs(this.$store.state.selectedPeriod.startDate).add(this.$dayjs(this.$store.state.selectedPeriod.startDate).utcOffset(), 'minute').format('X')),
        dateEnd: Number(this.$dayjs(this.$store.state.selectedPeriod.endDate).add(this.$dayjs(this.$store.state.selectedPeriod.endDate).utcOffset(), 'minute').format('X')),
      }
    },
    previousPeriodVariables() {
      return {
        projectId: Number(this.$store.state.selectedProject.id),
        dateStart: Number(this.$dayjs(this.$store.state.previousPeriod.startDate).add(this.$dayjs(this.$store.state.previousPeriod.startDate).utcOffset(), 'minute').format('X')),
        dateEnd: Number(this.$dayjs(this.$store.state.previousPeriod.endDate).add(this.$dayjs(this.$store.state.previousPeriod.endDate).utcOffset(), 'minute').format('X')),
      }
    }
  },
  methods: {
    fetchData(data) {
      let grossProfit = 0;
      let orders = 0;
      let cac = 0;
      switch (this.kpi) {
        case 'totalSales':
          return data.dashboardKPI.orders.totalSales ?? 0;
        case 'discounts':
          return data.dashboardKPI.orders.discountTotal ?? 0;
        case 'refunds':
          return data.dashboardKPI.orders.refundTotal ?? 0;
        case 'salesTaxes':
          return data.dashboardKPI.orders.totalTax ?? 0;
        case 'shippingCharged':
          return data.dashboardKPI.orders.totalShipping ?? 0;
        case 'itemSold':
          return data.dashboardKPI.orders.numberOfOrderItems ?? 0;
        case 'ltv':
          return data.dashboardKPI.lifeTime.lifeTimeValue ?? 0;
        case 'cac':
          return data.dashboardKPI.customer.newCustomers > 0 ?
              data.dashboardKPI.expenseBreakdown.adSpendAndMarketing / data.dashboardKPI.customer.newCustomers :
              0
        case 'newCustomers':
          return data.dashboardKPI.customer.newCustomers ?? 0;
        case 'returningCustomers':
          return data.dashboardKPI.customer.returningCustomers ?? 0;
        case 'netProfit':
          return data.dashboardKPI.expenseBreakdown.netProfit ?? 0;
        case 'grossProfit':
          return data.dashboardKPI.expenseBreakdown.grossProfit ?? 0;
        case 'netMargin':
          return data.dashboardKPI.expenseBreakdown.netMargin ?? 0;
        case 'grossMargin':
          return data.dashboardKPI.expenseBreakdown.grossMargin ?? 0;
        case 'averageOrderProfit':
          grossProfit = data.dashboardKPI.expenseBreakdown.grossProfit ?? 0;
          orders = data.dashboardKPI.orders.numberOfOrders ?? 0
          return orders > 0 ? grossProfit / orders : 0
        case 'cogs':
          return data.dashboardKPI.expenseBreakdown.cogs ?? 0
        case 'transactionFees':
          return data.dashboardKPI.expenseBreakdown.transactionCost ?? 0
        case 'shippingCosts':
          return data.dashboardKPI.expenseBreakdown.shippingCost ?? 0
        case 'gaAdsSpend':
          return data.dashboardKPI.expenseBreakdown.gaAdsSpend ?? 0
        case 'fbAdsSpend':
          return data.dashboardKPI.expenseBreakdown.fbAdsSpend ?? 0
        case 'ltvPerCac':
          cac = data.dashboardKPI.customer.newCustomers > 0 ?
              data.dashboardKPI.expenseBreakdown.adSpendAndMarketing / data.dashboardKPI.customer.newCustomers :
              0;
          return cac > 0 ? data.dashboardKPI.lifeTime.lifeTimeValue/cac : 0
        case 'repeatPurchaseRate':
          return data.dashboardKPI.customer.repeatPurchaseRate ?? 0;
        default:
          return 0
      }
    },
    openModal(kpi) {
      this.$refs.overviewModal.open(kpi)
    }
  }
}
</script>

<style scoped>

</style>