<template>
  <v-app>
    <Header v-if="['Homepage', 'Dashboard'].includes($route.name)"/>
    <v-main>
      <v-container class="page-content mb-16">
        <router-view/>
        <div class="localeChanger" v-if="$store.state.userData.role === 'ROLE_ADMIN'" @click="toggleLocale">
          <flag v-if="locale === 'hu'" iso="gb"/>
          <flag v-else iso="hu"/>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/common/Header";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {Header},
  data: () => ({
    locale: 'en'
  }),
  computed: {
    ...mapGetters({
      userData: 'getUserData'
    })
  },
  async mounted() {
    this.locale = localStorage.getItem('lang') ?? 'en'
    if (process.env.VUE_APP_INTERCOM_ENABLED === "true") {
      this.$intercom.shutdown();
      this.$intercom.boot(this.getIntercomData());
    }
  },
  methods: {
    getIntercomData() {
      const userData = this.userData;
      const hmac = crypto.createHmac('sha256', process.env.VUE_APP_INTERCOM_APP_SECRET)
          .update("" + userData.id)
          .digest('hex');
      return {
        user_id: "" + userData.id,
        name: userData.name,
        email: userData.email,
        user_hash: hmac
      };
    },
    toggleLocale() {
      const selectedLocale = this.locale === 'en' ? 'hu' : 'en'
      localStorage.setItem('lang', selectedLocale)
      this.$router.go();
    }
  }
};
</script>
