import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from "dayjs";
import Cookies from "js-cookie";
import dashboard from "./dashboard";
import {calculatePeriod} from "@/utils/periodCalculator";

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

Vue.use(Vuex)

function calculateMainKpiPeriods() {
  return {
    yesterday: calculatePeriod('yesterday'),
    aWeekAgo: calculatePeriod('aWeekAgo'),
    thisWeek: calculatePeriod('thisWeek'),
    lastWeek: calculatePeriod('lastWeek'),
    thisMonth: calculatePeriod('thisMonth'),
    lastMonth: calculatePeriod('lastMonth'),
    thisYear: calculatePeriod('thisYear'),
    lastYear: calculatePeriod('lastYear')
  }
}

function getSelectedPeriod() {
  let selectedPeriod = {
    startDate: dayjs().subtract(30, 'day').startOf('day').toDate(),
    endDate: dayjs().subtract(1, 'day').endOf('day').toDate()
  }
  if (Cookies.get("ConversificSelectedPeriod")) {
    const body = JSON.parse(Cookies.get("ConversificSelectedPeriod"))
    selectedPeriod = {
      startDate: dayjs.unix(body.startDate).subtract(dayjs.unix(body.startDate).utcOffset(), 'minute').toDate(),
      endDate: dayjs.unix(body.endDate).subtract(dayjs.unix(body.endDate).utcOffset(), 'minute').toDate()
    }
    Cookies.remove("ConversificSelectedPeriod", {path: '/', domain: '.conversific.com'})
    sessionStorage.setItem('selectedPeriod', JSON.stringify(selectedPeriod))
  } else if (sessionStorage.getItem('selectedPeriod')) {
    selectedPeriod = JSON.parse(sessionStorage.getItem('selectedPeriod'))
  }

  return selectedPeriod
}

function getPreviousPeriod() {
  let previousPeriod = {
    startDate: dayjs().subtract(60, 'day').startOf('day').toDate(),
    endDate: dayjs().subtract(31, 'day').endOf('day').toDate()
  }
  if (Cookies.get("ConversificPreviousPeriod")) {
    const body = JSON.parse(Cookies.get("ConversificPreviousPeriod"))
    previousPeriod = {
      startDate: dayjs.unix(body.startDate).subtract(dayjs.unix(body.startDate).utcOffset(), 'minute').toDate(),
      endDate: dayjs.unix(body.endDate).subtract(dayjs.unix(body.endDate).utcOffset(), 'minute').toDate()
    }
    Cookies.remove("ConversificPreviousPeriod", {path: '/', domain: '.conversific.com'})
    sessionStorage.setItem('previousPeriod', JSON.stringify(previousPeriod))
  } else if (sessionStorage.getItem('previousPeriod')) {
    previousPeriod = JSON.parse(sessionStorage.getItem('previousPeriod'))
  }

  return previousPeriod
}

export default new Vuex.Store({
  state: {
    periodType: 'last30Days',
    selectedPeriod: getSelectedPeriod(),
    previousPeriod: getPreviousPeriod(),
    mainKpiPeriods: {
      yesterday: calculatePeriod('yesterday'),
      aWeekAgo: calculatePeriod('aWeekAgo'),
      thisWeek: calculatePeriod('thisWeek'),
      lastWeek: calculatePeriod('lastWeek'),
      thisMonth: calculatePeriod('thisMonth'),
      lastMonth: calculatePeriod('lastMonth'),
      thisYear: calculatePeriod('thisYear'),
      lastYear: calculatePeriod('lastYear')
    },
    selectedProject: {},
    goalValue: 0,
    goalType: 'grossSales',
    userData: {
      id: '',
      name: '',
      email: '',
      role: 'ROLE_USER',
      conversificRole: 'FREE',
      subscriptionAccount: {
        activeSubscription: {
          plan: {
            name: 'undefined',
            code: ''
          },
          expirationDate: 0
        }
      },
      projects: [],
      ownProjects: []
    }
  },
  getters: {
    getUserData(state) {
      return state.userData
    },
    getSelectedProject(state) {
      return state.selectedProject
    },
    getGoalValue(state) {
      return state.goalValue
    },
    getGoalType(state) {
      return state.goalType
    },
    getPeriodType(state) {
      let periodType = state.periodType
      if (Cookies.get("ConversificDateType")) {
        periodType = Cookies.get("ConversificDateType")
        Cookies.remove("ConversificDateType", {path: '/', domain: '.conversific.com'})
        sessionStorage.setItem('periodType', periodType)
      } else if (sessionStorage.getItem('periodType')) {
        periodType = sessionStorage.getItem('periodType')
      }

      return periodType
    }
  },
  mutations: {
    changeSelectedPeriod(state, payload) {
      state.selectedPeriod = payload.range
      state.mainKpiPeriods = calculateMainKpiPeriods()
      switch (payload.type) {
        case 'thisMonth':
          state.previousPeriod = {
            startDate: dayjs(payload.range.startDate).startOf('day').subtract(1, 'month').toDate(),
            endDate: dayjs(payload.range.endDate).endOf('day').subtract(1, 'month').toDate(),
          }
          break;
        case 'lastMonth':
          state.previousPeriod = {
            startDate: dayjs(payload.range.startDate).subtract(1, 'month').startOf('month').toDate(),
            endDate: dayjs(payload.range.endDate).subtract(1, 'month').endOf('month').toDate(),
          }
          break;
        case 'lastWeek':
        case 'thisWeek':
          state.previousPeriod = {
            startDate: dayjs(payload.range.startDate).subtract(1, 'week').toDate(),
            endDate: dayjs(payload.range.endDate).subtract(1, 'week').toDate(),
          }
          break;
        case 'lastYear':
          state.previousPeriod = {
            startDate: dayjs(payload.range.startDate).subtract(1, 'year').toDate(),
            endDate: dayjs(payload.range.endDate).subtract(1, 'year').toDate(),
          }
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          let difference = Math.abs(dayjs(payload.range.startDate).diff(dayjs(payload.range.endDate), 'day'))
          difference = difference === 0 ? 1 : difference + 1
          state.previousPeriod = {
            startDate: dayjs(payload.range.startDate).startOf('day').subtract(difference, 'day').toDate(),
            endDate: dayjs(payload.range.startDate).endOf('day').subtract(1, 'day').toDate(),
          }
      }
      sessionStorage.setItem('selectedPeriod', JSON.stringify(state.selectedPeriod))
      sessionStorage.setItem('previousPeriod', JSON.stringify(state.previousPeriod))
    },
    setSelectedProject(state, payload) {
      state.selectedProject = payload
    },
    setConversificRole(state, payload) {
      state.selectedProject.projectRole = payload
    },
    setUserData(state, payload) {
      state.userData.email = payload.email;
      state.userData.id = payload.id;
      state.userData.name = payload.firstname;
      state.userData.role = payload.role;
      if (payload.ownAccount.activeSubscription) {
        state.userData.subscriptionAccount = payload.ownAccount;
      }
      state.userData.projects = payload.allProjects;
      state.userData.ownProjects = payload.ownProjects;
    },
    setPeriodType(state, payload) {
      state.periodType = payload
      sessionStorage.setItem('periodType', payload)
    }
  },
  actions: {
  },
  modules: {
    dashboard
  }
})
