<template>
    <div class="section-header">
      <span class="section-number">{{ number }}</span>
      <h2 class="section-title">{{ title }}</h2>
      <span v-if="infoTooltip">
        <info-button :info-tooltip="infoTooltip"/>
      </span>
      <slot name="button" class="section-button"></slot>
    </div>
</template>

<script>
import InfoButton from "./infoButton";
export default {
  name: "SectionHeader",
  components: {InfoButton},
  props: {
    title: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    infoTooltip: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>